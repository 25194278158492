import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights4() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container mg1 py-5">
<center><h1 class="display-6 mb-3">Controlled Correspondence Related To Generic Drug Development Guidance For Industry</h1></center><br></br>
<div class="container"><p class= "cr1">This guidance suggests regarding the process by which generic drug manufacturers and related industry or their representatives can submit to FDA controlled correspondence requesting information related to generic drug development. The Revisions include clarification about how the agency handles controlled correspondence related to a pending petition, what information should be submitted for requests related to an inactive ingredient, and when the agency may determine that an inquiry is a “complex” controlled correspondence.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/b3.png"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">The guidance defines “standard” versus “complex” controlled correspondence. Standard submissions are requests for information on a specific element of generic drug product development or are related to post-approval submission requirements that are not covered in guidance and are not specific to an abbreviated new drug application (ANDA). Complex submissions, on the other hand, involve the evaluation of clinical content or the review of bioequivalence (BE) protocols for drugs that reference listed drugs with risk evaluation and mitigation strategies (REMS) with elements to assure safe use (ETASU). Additionally, complex controlled correspondence could involve requested evaluations of alternative BE approaches within the same study type, such as pharmacokinetic, in vitro, or clinical. When requesters are seeking clarification on an existing controlled correspondence response that they consider ambiguous, they must submit their request for clarification within 7 calendar days of the FDA original response. If the request is not sent within 7 days, the agency will consider it to be a new controlled correspondence.</p></div>
  
     <br></br><br></br><br></br>
     <div class="container"><p class= "cr1">
     FDA agreed to review and respond to 90% of standard controlled correspondence within 60 calendar days of submission, 90% of complex controlled correspondence within 120 calendar days of submission, and 90% of requests for clarifications within 14 calendar days of submission. For controlled correspondence that is related to a pending petition, the 60 or 120 days starts from the date that the agency responds to the petition.
<br></br>
The FDA recommends that requesters to submit no more than three inactive ingredients and no more than three proposed levels for a drug product in any given control correspondence. Requests should also identify the reference listed drug (RLD), including the Q1 and Q2 with respect to specific drug product and strength.
</p></div><br></br><br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> controlled correspondence related to generic drug development guidance for industry. december 2020, generics.</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights4