import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Careers3() {
  return (
<>
<Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
<div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Regulatory Associate - Medical Writing</h1></center><br></br>
<center><p class= "cr1">We are hiring an experienced medical writer with experience in CTD medical writing and experience in clinical development of medicines.</p></center><br></br>
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/c11.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Responsibilities</h1>
                    <p class="mb-4">● Writing high quality CTD modules nonclinical and clinical overviews & summaries (Module 2.5, 2.4, 2.7, 2.7) as per requirements in EU submission<br></br>
                    ● Response to clinical deficiencies<br></br>
                    ● Assist with drug development strategy
<br></br> 
                    ● SmPC, Patient information leaflet, CCDS updates, Safety variations<br></br>
                    ● Clinical study reports, Study design and synopsis<br></br>
               
                    ● Assist in clinical development strategy Review of Study reports published papers<br></br>
                    ● Literature search and regulatory evaluation<br></br>
                    ● Rx to OTC evaluation<br></br>
                    ● PSURs, PADER, RMP<br></br>
                   
                    </p>
  
  
  </div></div>
  
  <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Requirements</h1>
                <p class="mb-4">● MBBS in Medicine/ M.Pharma in Pharmacy/ Medical-MS/MD in Pharmacology/ Ph.D/Doctorate in Pharmacy<br></br>
                    ● Experience in Medical writing for regulatory submissions in Europe.<br></br>
                    ● Non-clinicaloverview / Clinical overview / PSUR / EU submission / ClinicalPharmacology / module 2.5 /Scientific Writing ICH / Regulatory Submissions / Medicine / DCP / CTD / Clinical Research / medical writer / Medical Writing / EMA labelling Module 2.4<br></br> 
                    ● Scientific accuracy<br></br>
                    ● Attention to details<br></br>
                    ● Understanding of Generic, Hybrid and New drug applications<br></br> 
                    
                    ● Experience in Clinical study design and protocol<br></br> 
                    ● Excellent communication skills and time management<br></br>
                    ● Specialization in Pharmacology desired.<br></br>
                    
                   
                    </p>
               
              
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/c12.jpg" alt=""/>
                  
                   
                </div>
            </div>
  
  
  
  
  
  
  </div></div>
 <center> <a href='/Contact'>     <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a></center><br></br><br></br>
  <Fcontact></Fcontact>
 <Footer></Footer>




</>
  )
}

export default Careers3