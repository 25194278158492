import React from 'react'
import Navbar from '../Navbar';
import Fcontact from './Fcontact';
import Footer from './Footer';
function Home() {
  return (
  <>
  {/* <div id="spinner"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status" style={{width: "3rem", height: "3rem"}} ></div>
        
    </div> */}
   
    <Navbar></Navbar>
<br></br><br></br><br></br><br></br>

    <section id="home" class="w3l-banner py-5">
        <div class="banner-image">

        </div>
        <div class="banner-content">
            <div class="container pt-5 pb-md-4">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-6 pt-md-0 pt-4">
                    <h1 class="display-1 text-white animated slideInRight mb-3">Lab To Market</h1>
                        {/* <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
                            quia consequuntur magni dolores eos qui seequi.</p> */}
                        <div class="mt-md-5 mt-4 mb-lg-0 mb-4">
                            {/* <a class="btn btn-style" href="services.html">View our Works</a> */}
                        </div>
                    </div>
                    <div class="col-md-6 banner-right mt-lg-4 mt-0 text-right offset-lg-1">
                        {/* <img class="img-fluid" src="assets/images/banner2.jpg" alt=" "/> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
<br></br><br></br><br></br><br></br><br></br><br></br>
<center>
<h1 class="display-6 mb-4 wow fadeIn" data-wow-delay="0.3s">About Us</h1></center>
<div class="container-fluid container-service py-5"> 
        <div class="container pt-5">
           
            <div class="row g-4">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item">
                       
                        <h5 class="mb-3">API Services</h5>
                            <p class="mb-4">Our services encompass writing Active Substance Master Files (ASMF) or Drug Master Files (DMF) tailored to regulatory agency standards. We also facilitate the attainment of Certificate of Suitability to European Pharmacopoeia (CEP / COS) from EDQM."</p>
                            <br></br><br></br><br></br> <br></br> <br></br><a class="btn btn-light px-3" href="/Apis">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item">
                        
                        <h5 class="mb-3">Regulatory Affairs</h5>
                            <p class="mb-4">We offer comprehensive end-to-end regulatory support for global markets, emphasizing risk-based thinking and strategic approaches for timely submissions and swift market approvals. Our experts provide guidance and support from an agency assessor's perspective."</p>
                            <br></br><br></br><br></br><br></br><br></br> <a class="btn btn-light px-3" href="/Regulatorya">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        {/* <div class="icon-box-primary mb-4">
                            <i class="bi bi-virus text-dark"></i>
                        </div> */}
                        <h5 class="mb-3">Scientific Services</h5>
                            <p class="mb-4">Our scientific support services cover pre-development planning and extend through the assessment phase. We apply strategic thinking, regulatory expertise, and an assessor's perspective to assess regulatory, quality, safety, and efficacy aspects. With extensive experience in global agency requirements, including USFDA, EMA, MHRA, MEB, TGA, and others, our strategic approach guarantees success."</p>
                        <a class="btn btn-light px-3" href="/Scientifics">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="service-item">
                        {/* <div class="icon-box-primary mb-4">
                            <i class="bi bi-capsule-pill text-dark"></i>
                        </div> */}
                        <h5 class="mb-3">End To End Compliance</h5>
                            <p class="mb-4">Compliance to current Good Manufacturing Practice (cGMP) is essential for your long term success in the competitive market place. Ultimately a good quality management system ensures the patient compliance and manufacturing of safe medicines. We provide custom solutions towards your manufacturing compliance and stratgise GMP pipeline</p>
                            <br></br><br></br> <a class="btn btn-light px-3" href="/Endtoend">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item">
                        {/* <div class="icon-box-primary mb-4">
                            <i class="bi bi-capsule text-dark"></i>
                        </div> */}
                        <h5 class="mb-3">Clinical & Non-Clinical</h5>
                            <p class="mb-4">Our clinical /non-clinical experts have more than 20 years of experience working with different Clinical Research Organisations (CROs) and multinational pharmaceutical companies. These resources are equipped to provide you solutions to global drug development strategy.</p>
                            <br></br><br></br><br></br><br></br><br></br>  <a class="btn btn-light px-3" href="/Clinicalnc">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item">
                        {/* <div class="icon-box-primary mb-4">
                            <i class="bi bi-prescription2 text-dark"></i>
                        </div> */}
                        <h5 class="mb-3">Solution for CROs/CMOs</h5>
                            <p class="mb-4">Regulatory compliance is atmost of importance during the development and manufacturing of the product. Noncompliance issues and warnings from health authorities are common nowadays. It is important that data from development, manufacturing, and regulatory submissions are aligned and matched. We provide our expert support for compliance and ensure that the development meets the respective agency requirements</p>
                        <a class="btn btn-light px-3" href="/Solutioncc">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        {/* <div class="icon-box-primary mb-4">
                            <i class="bi bi-clipboard2-pulse text-dark"></i>
                        </div> */}
                        <h5 class="mb-3">Pharmacovigilance</h5>
                            <p class="mb-4">Drug safety is an emerging subject and most of the global agencies and pharmaceutical companies are paying much attention to meet the requirements in this area and achieve compliance. We have experts who can take care of pharmacovigilance requirements and provide all kinds of support. We can write all kinds of safety update reports like PSUR, PADER, DSUR, etc. as required regionally.</p>
                          <br></br><br></br> <a class="btn btn-light px-3" href="/Pharmacovigilance">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="service-item">
                        {/* <div class="icon-box-primary mb-4">
                            <i class="bi bi-file-medical text-dark"></i>
                        </div> */}
                        <h5 class="mb-3">Labelling Solutions</h5>
                            <p class="mb-4">One of the greatest challenge in pharmaceutical industry is patient compliance. Effective and novel packaging solutions can address this challenge to great extent. Compliance in labelling information is challenging. Harmonisation of product information is always another hurdle in EU. We provide complete packaging/labelling solutions for global markets, simplifying the complexities.</p>
                            <br></br> <a class="btn btn-light px-3" href="/Labellings">Read More<i class="bi bi-chevron-double-right ms-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="container-fluid mg py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/lini_photo1.png" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Lini Subin</h1>
                    <p class="mb-1"><font color='#000'>CEO/ Senior Regulatory Affairs Consultant</font></p>
                    <p class="mb-1">Lini is an Accomplished regulatory affairs professional with two decades of experience in End-to-End Regulatory Process & Requirements. She provides leadership within the area of complex medicinal products in Europe, including guidance on product development and regulatory strategy. Supported nearly 300 international companies in different regulatory affairs activities and she is able to support regulatory submission activities for any global markets.</p>
                  <br></br> <p class="mb-1">Her Specialties include.</p>
                    <p class="mb-4">● Strategical Advice on EU Registration procedures (DC/MR, CP and national)<br></br>
                    ● CMC Dossier Development & writing<br></br>
                    ● Regulatory Strategy - Global strategy for Generic Drugs, New Drugs, Generic fixed dose combinations & biosimilars/biologics<br></br> 
                    ● Regulatory Dossier Due Diligence<br></br>
                    ● Implementation and maintenance of electronic dossier tools<br></br>
                    ● Scientific advice meetings, Briefing packages and controlled correspondence with the regulators<br></br>
                    ● Dossier development for innovative drugs<br></br>
                    ● EU Lifecycle maintenance – All types of variations, renewal, update of product information based on safety updates<br></br>
                    ● CTD/NeeS/eCTD Compilation of dossiers<br></br>
                    ● Review and publishing in electronic format (eCTD)<br></br>
                    </p>
                   
                </div>
            </div>
           
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <h2 class="mb-4">Senior Medical Officer</h2>
                    <p><font color='#000'>Clinical Affairs</font></p>
                    <p class="mb-4">He brings 21+ years of experience in the Department of Clinical Pharmacology and Drug development with hands on experience in different areas such as Clinical operation (BA/BE studies and Clinical trials 1-4 phases), Pharmacovigilance, Regulatory and Quality management. He worked in reputed companies such as Novo Nordisk, Lotus lab (a Teva group of company), Jubilant Life sciences, Quartesian Clinical Research, Fortis Clinical Research, Notrox Research, Semler Research Centre etc.<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br></p>
                    
                    <hr/>
                 
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <h2 class="mb-4">Non-Clinical Expert</h2>
                    <p><font color='#000'>PhD Biochemistry</font></p>
                    <p class="mb-4">Over 30 years of experience as a toxicologist and leader in discovery and development of new chemical entities in pharmaceutical industries. Experienced in the development of preclinical program, study design, conduct, reporting and evaluating efficacy/safety data for new chemical entities, biotechnology products, impurities in drug substance/products, design and execution of bridging toxicology studies for 505 (b) (2) NDA filings; in training and managing staff; in dealing with regulatory bodies, contract research organizations; in writing non-clinical sections of IND/CTA applications, material Safety Data Sheets, expert assessment and preparation of occupational exposure limit (OEL) and Acceptable daily intake (ADI)/Permissible Daily Exposure (PDE) limit values for pharmaceutical manufacturing and cleaning validation and in developing and managing operations and capital budgets.</p>
                    
                    <hr/>
                 
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <h2 class="mb-4">Senior Clinical/Medical Expert</h2>
                    <p><font color='#000'>MD, PhD Organic Chemistry & Biochemistry, PhD Pharmacology</font></p>
                    <p class="mb-4">Extensive experience with and thought leadership on a range of Pharmaceutical operations (marketing, medical, compliance, clinical development, market access);Direct accountability for preparation of successful marketing and medical strategies and tactics and their implementation at both the local Pharma market and the Region Central & Eastern Europe;Comprehensive experience in clinical research that includes protocol writing, CT feasibility, roles and responsibilities of Local Study Responsible, Country Study Manager, carrying out medical monitoring and monitoring in selected clinical trials, preparation of clinical centers for audits both locally in Europe and internationally driven.</p>
                    
                    <hr/>
                 
                </div>
            </div>
        </div>
    </div>



    <div class="container-fluid testimonial py-5">
        <div class="container pt-5">
            <div class="row gy-5 gx-0">
                <div class="col-lg-6 pe-lg-5 wow fadeIn" data-wow-delay="0.3s">
                    <h1 class="display-6 text-white mb-4">Why Choose Us</h1>
                    <p class="text-white mb-5">Silmed Scientific is an initiative of Pharmaceutical Professionals, with vast experience in global Pharmaceutical industry. We pride end to end solutions and advices to companies for development and approval of safe medicines in international markets.We bring with us 20 years of excelllence in global regulatory affairs with proven track record in promoting global access of medicine through scientific support for Pharmaceutical development, manufacture compliance, packaging, submission process and commercialization of medicines. Our experts are leaders in the pharmaceutical industry and their experience and skill set will help successful market access.</p>
                    <a href="/About" class="btn btn-primary py-3 px-5">Know More</a>
                </div>
                {/* <div class="col-lg-6 mb-n5 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="mg ">
                    <img class="img-fluid w-100" src="./ass/img/a1.png" alt=""/> 
                    </div>
                </div> */}
            </div>
        </div>
    </div>








    <div class="container-fluid py-5">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="row g-0">
                        <div class="col-6">
                            <img class="img-fluid" src="./ass/img/b1.jpg"/>
                        </div>
                        <div class="col-6">
                            <img class="img-fluid" src="./ass/img/b5.jpg"/>
                           
                        </div>
                        <div class="col-6">
                            <img class="img-fluid" src="./ass/img/b3.png"/>
                        </div>
                        <div class="col-6">
                            <div class="bgp w-100 h-130 mt-n5 ms-n5 d-flex flex-column align-items-center justify-content-center">
                               
                                
                                <small class="fs-5 text-white"></small>
                            </div>
                        </div>
                    </div>
                </div>

             
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-4">Insights</h1>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tellus augue, iaculis id elit eget, ultrices pulvinar tortor. Quisque vel lorem porttitor, malesuada arcu quis, fringilla risus. Pellentesque eu consequat augue.</p>
                    <div class="row g-4 g-sm-5 justify-content-center">
                        <div class="col-sm-6">
                            <div class="about-fact btn-square flex-column rounded-circle  ms-sm-auto">
                                <p class="text-white mb-0"></p>
                             
                            </div>
                        </div>
                        <div class="col-sm-6 text-start">
                            <div class="about-fact1 btn-square flex-column rounded-circle bg-secondary me-sm-auto">
                                <p class="text-white mb-0"></p>
                               
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="about-fact2 mt-n130 btn-square flex-column rounded-circle bg-dark mx-sm-auto">
                                <p class="text-white mb-0"></p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid feature mt-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
            <div class="row g-0">
                <div class="col-lg-6 pt-lg-5">
                    <div class="bg-white p-5 mt-lg-5">
                        <h1 class="display-6 mb-4 wow fadeIn" data-wow-delay="0.3s">News</h1>
                        <p class="mb-4 wow fadeIn" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tellus augue, iaculis id elit eget, ultrices pulvinar tortor. Quisque vel lorem porttitor, malesuada arcu quis, fringilla risus. Pellentesque eu consequat augue.</p>
                        <div class="row g-5 pt-2 mb-5">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                               
                              
                                <h5 class="mb-3">Company News</h5>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                               
                                <h5 class="mb-3">Market Access</h5>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                            </div>
                        </div>
                        <a class="btn btn-primary py-3 px-5 wow fadeIn" data-wow-delay="0.5s" href="">Explore More</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row h-100 align-items-end">
                        <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                           
                        </div>
                        <div class="col-12">
                            <div class="gh p-5">
                                <div class="experience mb-4 wow fadeIn" data-wow-delay="0.3s">
                                   
                                 
                                </div>
                              
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><br></br><br></br>
<div class="container py-5">
    <center>
<h1 class="display-6 mb-4 wow fadeIn" data-wow-delay="0.3s">Blogs</h1></center>
<br></br>
    <div class="row g-4">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c1.jpg" alt=""/>
                          
                        </div>
                        <div class="text-center p-4">
                            <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c2.jpg" alt=""/>
                            {/* <div class="team-social">
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-youtube"></i></a>
                            </div> */}
                        </div>
                        <div class="text-center p-4">
                        <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c3.jpg" alt=""/>
                           
                        </div>
                        <div class="text-center p-4">
                        <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c4.jpg" alt=""/>
                           
                        </div>
                        <div class="text-center p-4">
                        <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
            </div>

            </div>














<Fcontact></Fcontact>















<Footer></Footer>




   

  
  
   
 
  
  
  
  
  
  
  
  
  
  </>
  )
}

export default Home