import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights13() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Q1/Q2 Assessment</h1></center><br></br>
<div class="container"><p class= "cr1">Q1/Q2 is a term referring to inactive ingredient assessments in ANDAs. A proposed generic formulation is similar to reference listed drug (RLD), if it contains – The same inactive ingredients (Qualitatively) and same concentration (Quantitively). FDA’s guidance sometimes recommends certain BE studies for drug products that are Q1/Q2 with respect to the RLD. Q1 assessment demonstrate qualitative sameness, identify and inactive ingredient and provide information about grade and chemistry of each excipient. Q2 assessments demonstrate quantitative sameness; determine the concentration of inactive ingredient and FDA generally accepts ±5% variation in each excipient.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/b8.png"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Control correspondence can be sent to agency with respect to any queries related to regulation requiring Q1/Q2 sameness. The query should contain complete details of RLD like RLD application holder, application number, API used, Dosage form, Route of administration, approval and market status etc. FDA recommends that no more than three proposed Q1/Q2 formulations of a single drug product be submitted in one controlled correspondence. Limiting a single controlled correspondence to no more than three formulation requests allows for FDA’s targeted and timely review of such requests. Requestor should not seek Q1/Q2 formulation assessment for generic drugs with different RLDs in a single controlled correspondence. Based on Q1/Q2 sameness or non Q1/Q2 formulation FDA recommends different BE studies.</p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> controlled correspondence related to generic drug development guidance for industry. december 2020, generics.</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights13