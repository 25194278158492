import React from 'react'

function Footer() {
  return (
  <>
  
  <div class="container-fluid footer position-relative bg-dark text-white-50 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <div class="row g-5 py-5">
                <div class="col-lg-3 pe-lg-5">
                <h4 class="text-light mb-4">Company</h4>
                            <a class="btn btn-link" href="/Career">Career</a>
                            <a class="btn btn-link" href="/Globalcoverage">Global Coverage</a>
                            <a class="btn btn-link" href="/Learning">Learning</a>
                            <a class="btn btn-link" href="/Webiner">Webiner</a>
                            <a class="btn btn-link" href="/">Podcast</a>
                </div>
                <div class="col-lg-3 pe-lg-5">
                <h4 class="text-light mb-4">Corporate Link</h4>
                            <a class="btn btn-link" href="/Services">Services</a>
                            <a class="btn btn-link" href="/Training">Training</a>
                            <a class="btn btn-link" href="/">Industries</a>
                            <a class="btn btn-link" href="/News">News & Events</a>
                            <a class="btn btn-link" href="/About">About Us</a>
                </div>
                <div class="col-lg-6 ps-lg-5">
                    <div class="row g-5">
                        <div class="col-sm-4">
                            <h4 class="text-light mb-4">Services</h4>
                            <a class="btn btn-link" href="/Regulatorya">Regulatory Services</a>
                            <a class="btn btn-link" href="/Apis">Api service</a>
                            <a class="btn btn-link" href="/Scientifics">Scientific Services</a>
                            <a class="btn btn-link" href="/Endtoend">End to end Compliance</a>
                            <a class="btn btn-link" href="/Clinicalnc">Clinical & non Clinical</a>
                            <a class="btn btn-link" href="/Pharmacovigilance">Pharmacovigilance</a>
                            <a class="btn btn-link" href="/Labellings">Labelling Solutions</a>
                        </div>
                        <div class="col-sm-4">
                            <h4 class="text-light mb-4">Industries</h4>
                            <a class="btn btn-link" href="">PharmaReg</a>
                            <a class="btn btn-link" href="">HerbReg</a>
                            <a class="btn btn-link" href="">FsReg</a>
                            <a class="btn btn-link" href="">MDReg</a>
                            <a class="btn btn-link" href="">BioReg</a>
                        </div>
                        <div class="col-sm-4">
                            <h4 class="text-light mb-4">Trainings</h4>
                            <a class="btn btn-link" href="">Onsite training</a>
                            <a class="btn btn-link" href="">Online training</a>
                            <a class="btn btn-link" href="">Workshops</a>
                        
                        </div>
                        <div class="col-sm-12">
                          
                            <div class="w-100">
                            <div class="d-flex mt-4">
                        <a class="btn btn-lg-square btn-primary me-2" href="#"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-lg-square btn-primary me-2" href="#"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-lg-square btn-primary me-2" href="#"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-lg-square btn-primary me-2" href="#"><i class="fab fa-instagram"></i></a>
                    </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <div class="container-fluid copyright bg-dark text-white-50 py-4">
        <div class="container">
            <div class="row">
                <div class="col-md-6 text-center text-md-start">
                    <p class="mb-0">&copy; <a href="#">Silmed Scientific 2023</a>. All Rights Reserved.</p>
                </div>
                <div class="col-md-6 text-center text-md-end">
                
                    <p class="mb-0">Designed by <a href="https://www.infosiltechnolabs.com/"> <img src="./ass/img/ilogo.png" class="infosil" alt=""/></a></p>
                </div>
            </div>
        </div>
    </div>
  
    <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i class="bi bi-arrow-up"></i></a>
  
  
  
 
  
  
  
  
  
  
  
  
  
  
  
  
  </>
  )
}

export default Footer