import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Careers1() {
  return (
<>
<Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
<div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Senior Medical Writer</h1></center><br></br>
<center><p class= "cr1">We at Silmed Scientific are empowered with a vision of public health safety with scientific evaluation and assessment of healthcare products and ensuring global reach of safe products. With scientific talent force located globally, our endeavour is to build a uniqueworking culture.</p></center><br></br>
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/cc1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Responsibilities</h1>
                    <p class="mb-4">● We are looking for experienced Regulatory Medical Writers<br></br>
                    ● Authoring and review of Regulatory submission documents for obtaining marketing authorization of pharmaceuticals in Europe and United States – Common Technical Document (CTD) modules such as nonclinical and clinical overviews & summaries (such as Module 2.4, 2.5, 2.6 and 2.7), expert reports, safety & efficacy summaries; Aggregate safety reports such as Periodic Safety Update Reports ( PSURs), bridging reports, Periodic Adverse Drug Experience Reports (PADER), policy papers, RMPs, etc.<br></br>
                    ● Product monographs, SmPCs, Patient information leaflets
<br></br> 
                    ● Regulatory strategy reports<br></br>
                    ● Advice on clinical/nonclinical Development questions/biowaiver reports<br></br>
               
                    
                    
                   
                    </p>
  
  
  </div></div>
  
  <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Requirements</h1>
                <p class="mb-4">● MPharm or Ph.D. Pharmacology<br></br>
                    ● MBBS or MD Pharmacology<br></br>
                    ● Experience of 2-8 Years in Medical writing<br></br> 
                    ● Excellent communication and Writing Skills.<br></br>
                    ● Prior experience in Regulatory Medical writing for EU Submissions with reputed pharma companies.<br></br>
                    ● Proven track record in writing CTD modules 2.4 and 2.5 for generic and bibliographic applications a must<br></br> 
                    
                    ● Ability to present research data to the target audience in a reader friendly manner.<br></br> 
                    ● In-depth knowledge of various therapeutic areas.<br></br>
                    ● Knowledge of relevant guidelines related to structure and contents of the specific documents with a good understanding of Vancouver style of referencing.<br></br>
                    ● Familiar with searching medical literature databases such as PubMed, EMBASE, etc<br></br> 
                   
                    </p>
               
              
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/cc2.jpg" alt=""/>
                  
                   
                </div>
            </div>
  
  
  
  
  
  
  </div></div>
 <center> <a href='/Contact'>     <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a></center><br></br><br></br>
  <Fcontact></Fcontact>
 <Footer></Footer>




</>
  )
}

export default Careers1