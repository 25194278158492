import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Pharmacovigilance() {
  return (
  <>
  
  <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Pharmacovigilance
           </h2>
          <p>
          Drug safety is an emerging subject and most of the global agencies and pharmaceutical companies are paying much attention to meet the requirements in this area and achieve compliance. We have experts who can take care of pharmacovigilance requirements and provide all kinds of support. We can write all kinds of safety update reports like PSUR, PADER, DSUR, etc. as required regionally.
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/s7.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">List of Services</h1>
                    <p class="mb-4">● Periodic Safety Update Reports (PSUR)<br></br>
                    ● Risk Management Plan (RMP) writing<br></br>
                    ● Review of safety updates<br></br> 
                    ● ICSR<br></br>
                    ● Narrative writing<br></br>
                    ● Literature search<br></br> 
                    ● SAE – collection of data from sites, preparing final documents/dossiers, submission to DCGI<br></br>
                    ● Local QPPV availability for India<br></br>
                    ● Training and establishment of team<br></br>
                   
                    </p>
  
  
  </div></div></div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  
  
  
  
  
  
  </>
  )
}

export default Pharmacovigilance