import React from 'react'

function Fcontact() {
  return (
   <>
   
<div class="container-fluid  py-5">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h1 class="display-6 mb-4">Contact</h1>
               <div class="row">
                <div class="col-sm-6">
                     <div class="d-flex align-items-start wow fadeIn" data-wow-delay="0.3s">
                        <div class="">
                        <i class="fa-solid fa-location-dot text-dark fs-1"></i>
                      
                        </div>
                        <div class="ms-3">
                            <h5>Corporate Address</h5>
                            <span>Silmed Scientific Pvt Ltd.,
T-303C, Lucky Paradise Complex, 22nd Cross Rd, 8th F Main Rd, Near ICICI Bank, Jayanagar, 3rd Block, Bangaluru, Karnataka 560011</span>
                        </div>
                    </div>
                   
                    <hr/></div>
                    <div class="col-sm-6">
                    <div class="d-flex align-items-start wow fadeIn" data-wow-delay="0.4s">
                  
                    <i class="fa-solid fa-location-dot text-dark fs-1"></i>
                       
                        <div class="ms-3">
                            <h5>Registered Address</h5>
                            <span>Silmed Scientific Pvt Ltd.,
Second FLoor, Golden Plaza,TB Junction, Angamaly, Cochin, Kerala, 683572</span><br></br><br></br><br></br>
                       </div> </div>
                       <hr/> </div></div>


                       <div class="row">
                <div class="col-sm-6">
                     <div class="d-flex align-items-start wow fadeIn" data-wow-delay="0.3s">
                        <div class="">
                     
                        <i class="fa-solid fa-envelope text-dark fs-1"></i>
                        </div>
                        <div class="ms-3">
                            <h5>Email</h5>
                            <span>admin@silmedscientific.com</span><br></br><br></br>
                        </div>
                    </div>
                   
                    <hr/></div>
                    <div class="col-sm-6">
                    <div class="d-flex align-items-start wow fadeIn" data-wow-delay="0.4s">
                  
                   
                    <i class="fa-solid fa-phone text-dark fs-1"></i>
                        <div class="ms-3">
                            <h5> Phone</h5>
                            <span>+91 9920125387<br></br>
+91 802 663 4572</span>
                       </div> </div>
                       <hr/> </div></div>









                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <h1 class="display-6 mb-4">Contact</h1><br></br>
                    <div class="row g-3">
                        <div class="col-sm-6">
                            <div class="form-floatin">
                                <input type="text" class="form-control" id="name" placeholder="Your Name"/>
                              
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-floatin">
                                <input type="email" class="form-control" id="mail" placeholder="Your Email"/>
                             
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-floatin">
                                <input type="text" class="form-control" id="mobile" placeholder="Your Mobile"/>
                             
                            </div>
                        </div>
                        <div class="col-sm-6">
                        <div class="form-floatin">
                                <input type="text" class="form-control" id="mobile" placeholder="Your Mobile"/>
                             
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floati">
                                <textarea class="form-control" placeholder="Leave a message here" id="message"
                                    style={{height:"130px"}}></textarea>
                             
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button class="btn btn-primary w-100 py-3" type="submit">Submit Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   
   
   
   
   
   
   
   </>
  )
}

export default Fcontact