import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights1() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Alternate Electronic Format</h1></center><br></br>
<div class="container"><p class= "cr1">An alternate electronic format for submissions is covered under an exemption from or a waiver of the requirements of section 745A (a) of the Federal Food, Drug, and Cosmetic Act (FD&C Act). This guideline gives recommendation about the format of content contained in new drug applications (NDAs), abbreviated new drug applications (ANDAs), certain drug master files (DMFs), certain biologics license applications (BLAs), and certain investigational new drug applications (INDs) submitted to the Center for Drug Evaluation and Research (CDER) or to the Center for Biologics Evaluation and Research (CBER).</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i1.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Sponsors and applicants, who receive an exemption or a waiver from filing in electronic common technical document (eCTD), should still submit the exempted document electronically. Although the alternate electronic format utilizes the same folder structure found in eCTD submissions, it does not include XML and other specific files needed for electronic display. In other words it does not require any special software. Alternate electronic format should follow FDA’s technical specification The Comprehensive Table of Contents Headings and Hierarchy. Before making the first alternate electronic submission, a pre-assigned application number should be obtained by contacting CDER or CBER. The submission content should be consistent with International Council for Harmonization (ICH) guidance for industry M4 Organization of the Common Technical Document for the Registration of Pharmaceuticals for Human Use. The FDA Electronic Submissions Gateway (ESG) enables the secure submission of regulatory information for review for document of 10GB or smaller. For submissions that are greater than 10 GB, refer to the FDA technical specification Transmitting Electronic Submissions using eCTD Specifications.</p></div>
  
     <br></br><br></br><br></br>
     <div class="container"><p class= "cr1">All documents and data files should be placed in a main folder using the application type and number as the folder name. A table of contents with hyperlinks and bookmarks should be provided in numerical order for the ease of navigation. There should be 5 or fewer subfolders(m1,m2, m3, m4 and m5).</p></div><br></br><br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> providing regulatory submissions in alternate electronic format guidance for industry, july 2021, electronic submissions</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights1