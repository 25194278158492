import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights12() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container mg1 py-5">
<center><h1 class="display-6 mb-3">Product Name Placement, Size, And Prominence In Promotional Labeling And Advertisements Guidance For Industry.</h1></center><br></br>
<div class="container"><p class= "cr1">The guidance gives clarity with respect to the requirements for product name placement, size, prominence, and frequency in promotional labeling and advertisements for prescription drugs. This helps in proper identification and to ensure safe and effective use.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i11.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">FDA recommends that the established name be placed either directly to the right of or directly below the proprietary name. FDA also recommends that the proprietary name and the established name not be separated by placement of intervening matter that would in any way detract from, obfuscate, or de-emphasize the established name of the product or obscure the relationship between the proprietary name and the established name. Trademarks are not considered as intervening matter. In promotional labeling and advertisements, the proprietary name and established name should be in same size. If proprietary name is in running text the established name should be at least half the size. The established name shall have a prominence commensurate with the prominence with which such proprietary name or designation appears, taking into account all pertinent factors, including typography, layout, contrast, and other printing features. In audiovisual promotional labeling, FDA recommends that the established name be displayed on the screen for the same amount of time as the proprietary name. In electronic or computer based promotion the established name should accompanies the proprietary name at least once per web page.
     
     <br></br><br></br><br></br>
     When the product contains more than one active ingredient, and proprietary name doesn’t contain single established name, FDA recommends that the active ingredients be placed either directly to the right of or directly below the proprietary name. Active ingredient information should ot be separated from proprietary name.
     
     
     </p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> product name placement, size, and prominence in promotional labeling and advertisements guidance for industry</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights12