import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Apis() {
  return (
  <>
  
  <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">API Services
           </h2>
          <p>
          Our End to End solutions includes expert services for Active Ingredient Manufacturers. We are capable of writing Active Substance Master Files (ASMF) or Drug Master Files (DMF) per requirements of regulatory agencies. Chemistry, Manufacturing, and control information in open and closed parts of DMF is the cornerstone of any drug development strategy. Also, we help to secure Certificate of suitability to European Pharmacopoeia (CEP/COS) from EDQM. Many global agencies require the DMF to be submitted in eCTD format, which is relatively a new requirement. We have the expertise to help API manufacturers comply with this requirement.
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/ap1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Solutions For API Manufacturers</h1>
                    <p class="mb-4">● eCTD Publishing<br></br>
                    ● DMF Compilation / Review<br></br>
                    ● Dossier for CEP - Preparation and submission<br></br> 
                    ● QP audit<br></br>
                   
                    </p>
  
  
  </div></div></div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  
  
  
  
  
  
  </>
  )
}

export default Apis