import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights9() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Product Development:</h1></center><br></br>
<div class="container"><p class= "cr1">Product development is to design a quality product and its manufacturing process to consistently deliver the intended performance of the product. Main component of development are Drug substance and excipient. Active pharmaceutical ingredients Physiochemical, biological properties can influence on the drug product performance and manufacturability. Based on API nature the excipients will be selected and compatibility of the same will be studied. Based on dosage form requirement further process and dosage form will be finalized.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i8.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Lately in product development “Quality by Design” concept is gaining much interest in order to maintain safety efficacy and quality of the product. Key elements of QbD includes quality target product profile(QTPP), Critical quality attributes (CQA), Critical process parameter (CPP), Quality risk management (QRM), Design space, control strategy and continues life cycle management. With the key elements QbD identifies all critical attributes and parameters and improves the process understanding and reduces the variation by control strategy. By risk assessment the high risk attributes can be studies and appropriate measures can be taken to mitigate the risk. QBD serves as a bridge between regulatory bodies and pharmaceutical industries with more scientific and risk based approach.
     <br></br><br></br><br></br>
 
     Product developed with QBD approach will result in higher level of process and product understanding thus reduces the need of post approval changes. If the changes are made within design space, requirement of post approval is deemed not necessary. QbD is a quality system that builds on past and sets future regulatory expectations. The QbD documents can and should be revised as the knowledge base changes

   
     
     
     
     
     
     
     </p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i>ich harmonized tripartite guideline pharmaceutical development q8(r2) current step 4 version dated august 2009.</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights9