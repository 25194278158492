import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Contact() {
  return (
 <>
   <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
 <Fcontact></Fcontact>
 <Footer></Footer>
 </>
  )
}

export default Contact