import React from 'react'

function Navbar() {
  return (
    <>
   
   <nav class="navbar navbar-expand-lg ">

<a class="navbar-toggler fd" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
<font color='#000'><i class="fa-solid fa-bars"></i></font>
</a>
<div class="collapse navbar-collapse" id="navbarNav">
  <div class="plz">
  <ul class="navbar-nav">
    <li class="nav-item1 active">
      <a class="nav-link1" href="/Careers">Career <span class="sr-only"></span></a>
    </li>
    <li class="nav-item1">
      <a class="nav-link1" href="/Globalcoverage">Global</a>
    </li>
    {/* <li class="nav-item1">
      <a class="nav-link1" href="#">Insights</a>
    </li> */}
    <li class="nav-item1">
      <a class="nav-link1 " href="/Learning">Learning</a>
    </li>
    <li class="nav-item1">
      <a class="nav-link1 " href="/Webiner">Webiner</a>
    </li>
    <li class="nav-item1">
      <a class="nav-link1 " href="/Podcast">Podcast</a>
    </li>
    <li class="nav-item1">
      <a class="nav-link1 " href="#"><i class="fa-solid fa-cart-shopping"></i></a>
    </li>
  </ul></div>
</div>
</nav>

  <header id="site-header" class="">
      <div class="container-fluid">
          <nav class="navbar navbar-expand-lg stroke">
              {/* <h1>SilmedLogo
                  <a class="navbar-brand d-flex align-items-center" href="index.html">
                      <i class="fas fa-tools mr-1" aria-hidden="true"></i>Industries</a>
              </h1> */}
       
  <a class="navbar-brand" href="/">
      <img src="./ass/img/SilmedLogo.png" alt="Your logo" title="Your logo" class="logo" />
  </a> 
  <a class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
 <font color='#000'><i class="fa-solid fa-bars"></i></font> 
    </a>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                  <ul class="navbar-nav ml-lg-auto">
                      <li class="nav-item active">
                          <a class="nav-link2" href="/About">About US</a>
                      </li>
                    
    <li class="nav-item">
        <a class="nav-link2" href="/Services">Services</a>
      
    </li>

                      <li class="nav-item">
                          <a class="nav-link2" href="/Training">Training</a>
                      </li>
                      {/* <li class="nav-item">
                          <a class="nav-link2" href="/News">News & Blogs</a>
                      </li> */}
                      <li class="nav-item dropdown position-static">
          <a class="nav-link2 dropdown-toggle" href="/News" id="navbarDropdown" role="button"
            data-mdb-toggle="dropdown" aria-expanded="false">
         News & Blogs
          </a>
        
          <div class="dropdown-menu  mt-0" aria-labelledby="navbarDropdown">
            <div class="container">
              <div class="row my-4">
                <div class="col-md-3 ">
                  <div class="list-group list-group-flush">
                  <img class="img-fluid w-100" src="./ass/img/c4.jpg" alt=""/>
                  </div>
                </div>
                <div class="col-md-3 ">
                  <div class="list-group list-group-flush">
                  <img class="img-fluid w-100" src="./ass/img/c3.jpg" alt=""/>
                  </div>
                </div>
                <div class="col-md-3 ">
                  <div class="list-group list-group-flush">
                  <img class="img-fluid w-100" src="./ass/img/c1.jpg" alt=""/>
                  </div>
                </div>
                {/* <div class="col-md-6">
                  <div class="list-group list-group-flush">
                  <img class="img-fluid w-100" src="./ass/img/c2.jpg" alt=""/>
                  </div>
                </div> */}
                  <div class="col-md-3">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null
                    
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
                      {/* <li class="nav-item">
                          <a class="nav-link2" href="/Insights">Insights</a>
                      </li> */}
                      <li class="nav-item dropdown position-static">
          <a class="nav-link2 dropdown-toggle" href="/Insights" id="navbarDropdown" role="button"
            data-mdb-toggle="dropdown" aria-expanded="false">
           Insights
          </a>
        
          <div class="dropdown-menu  mt-0" aria-labelledby="navbarDropdown">
            <div class="container">
              <div class="row my-4">
                <div class="col-md-3">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                    <a href="" class="list-group-item list-group-item-action">null</a>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </li>



        <li class="nav-item">
                          <a class="nav-link2" href="/Product">Shop</a>
                      </li>
                   











                      <li class="nav-item">
                          <a class="nav-link2" href="/Contact">Contact</a>
                      </li>
                   

                      <li class="nav-item dropdown">
    <a class="nav-link2 " href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa-solid fa-earth-americas"></i>
    </a>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a class="dropdown-item" href="#">English</a>
        <a class="dropdown-item" href="#">Spanish</a>
    </div>
</li>

                      <li class="nav-item">
                          <a class="nav-link2 hid" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="contact.html">  <img src="./ass/img/fast-backward.gif"  class="sety"alt="Your logo" /></a>
                      </li>


<div class="offcanvas navi offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel"></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
  <a class="" href="/">
      <img src="./ass/img/SilmedLogo.png" alt="Your logo" title="Your logo" class="logo1" />
  </a>
  <div class="ofno">
  <h3 class="mb-3 hovrypage1">About</h3>
  <div class="hai1" >
<div class="row">
<div class="col-sm-11">

<img class="img-fluid w-100 abonan" src="./ass/img/abnav.jpg" alt="" />
<div class="overlay">
<h1 class="display-6 mb-3 text-white">
Innovate, ignite, Analyse, Together for Market Access</h1>
        </div>
<div class="row no-gutters">
  <div class='col-sm-4'>
  <img class="img-fluid w-100" src="./ass/img/abnav1.jpg" alt="" />


  </div>
  <div class='col-sm-4'>
  <img class="img-fluid w-100" src="./ass/img/abnav2.jpg" alt="" />


  </div>
  <div class='col-sm-4'>
  <img class="img-fluid w-100 lastimg" src="./ass/img/abnav3.jpg" alt="" />


  </div>
</div>



</div>
<div class="col-sm-1"></div>
</div>

















  
</div>





  <h3 class="mb-3 hovrypage">Services</h3>

  <div class="hai" >
    <div class="row">
<div class="col-sm-11 navg ">
<div class="">
  {/* <img class="img-fluid w-100" src="./ass/img/nav.jpg" alt="" /> */}
  <div class="">
  <div class="content">
         
         <div class="row">
           
           <ul class="mega-links">
            <li class="Regulatory">
            <h3 class="mb-3 "><a href='#'>Regulatory Affairs</a></h3>
             <div class="submenu">
               
               <ul class="mega-links">
                 <li class="sub-submenu">
                 <h3 class="mb-3 "> <a href="#">EU Marketing Authorization Application (MAA) Services</a></h3>
                   <ul class="EUsubmenu">
                   <h3 class="mb-3 "> <a href="#"> EU registration strategy</a></h3>
                   <h3 class="mb-3 "><a href="#"> Regulatory support for DCP/ MRP / NP / CP applications</a> </h3>
                   <h3 class="mb-3 "><a href="#"> Selection of Member States</a></h3>
                   <h3 class="mb-3 "> <a href="#"> Local representation and query handling</a> </h3>
                   <h3 class="mb-3 "> <a href="#"> Response to deficiencies</a> </h3>
                   <h3 class="mb-3 "> <a href="#"> Clinical expert consultation</a> </h3>
                   <h3 class="mb-3 "> <a href="#"> Scientific Advice</a> </h3>
                   <h3 class="mb-3 "> <a href="#"> Submission management services</a> </h3>
                   <h3 class="mb-3 ">  <a href="#"> Brexit Advice</a> </h3>




                   </ul>
                 </li>
               
                 <li class="sub-submenu">
                 <h3 class="mb-3 "> <a href="#">Regulatory Affairs Global Registration Support services</a></h3>
                   <ul class="EUsubmenu1">
                    
                   <h3 class="mb-3 "> <a href="#">Dossier Due Diligence</a></h3>
                   <h3 class="mb-3 "> <a href="#">Dossier Writing CMC/Medical writing / Clinical document</a></h3>
                   <h3 class="mb-3 "> <a href="#">Preparation (Module 1, Module 2.4, 2.5, 2.6, 2.7, Module 3 etc)</a></h3>
                   <h3 class="mb-3 "> <a href="#">eCTD Publishing</a></h3>
                   <h3 class="mb-3 "><a href="#">Due Diligence (For submission and Market acquisition)</a></h3>
                   <h3 class="mb-3 "> <a href="#">Labelling, Package Leaflet, Artwork</a></h3>




                   </ul>
                 </li>
                 <li class="sub-submenu">
                 <h3 class="mb-3 ">  <a href="#">Post Approval/Life Cycle Management</a></h3>
                   <ul class="EUsubmenu2">
                    
                   <h3 class="mb-3 "><a href="#">Variations– Advice/ Preparation/Strategy</a></h3>
                   <h3 class="mb-3 "> <a href="#">MAH transfers/Change of Ownership applications</a></h3>
                   <h3 class="mb-3 "><a href="#">PIQ packages</a></h3>
                   <h3 class="mb-3 "> <a href="#">Sunset clause advice</a></h3>
                   <h3 class="mb-3 "> <a href="#">Renewal Strategy and advice</a></h3>
                   <h3 class="mb-3 "> <a href="#">Product information updates</a></h3>
                   <h3 class="mb-3 "> <a href="#">SUPAC change evaluation</a></h3>



                   </ul>

                 </li>
                 <li class="sub-submenu">
                 <h3 class="mb-3 "> <a href="#">US ANDA/NDA services</a></h3>
                   <ul class="EUsubmenu3">
                    
                   <h3 class="mb-3 ">  <a href="#">US agent services</a></h3>



                   </ul>


                 </li>
                 
               </ul>
             
             </div>
             
           </li>
          
             <li class="API">
            <h3 class="mb-3">  <a href="#">API Services</a></h3>
               <ul class="APIsubmenu">
               <h3 class="mb-3 "> <a href="#">eCTD Publishing</a></h3>
               <h3 class="mb-3 "> <a href="#">DMF Compilation / Review'</a>   </h3>
               <h3 class="mb-3 "> <a href="#">Dossier for CEP - Preparation and submission</a></h3>
               <h3 class="mb-3 "> <a href="#">QP audit</a></h3>
               </ul>
             </li>
           
             <li class="Scientific">
             <h3 class="mb-3 ">   <a href="#">Scientific Services</a></h3>
               <ul class="Scientificsubmenu">
               <h3 class="mb-3 ">  <a href="#">Early stage due diligence / gap analysis</a></h3>
               <h3 class="mb-3 ">  <a href="#">Organise/ Attend EMA/ MHRA/ other EU Agency Scientific Advise Meetings.</a></h3>
               <h3 class="mb-3 "> <a href="#">Prepare for the meeting/ Develop content for negotiation at agency meetings.</a></h3>
               <h3 class="mb-3 ">  <a href="#">Strategy for discussing with agencies for further development</a></h3>
               <h3 class="mb-3 "> <a href="#">India DCGI/CDSCO meetings</a></h3>
               <h3 class="mb-3 "> <a href="#">Orphan drug evaluation/advice on regulatory queries</a></h3>
               <h3 class="mb-3 "> <a href="#">Similarity assessment/evaluation of characterization/comparability data assessment for biologics</a></h3>
               <h3 class="mb-3 ">  <a href="#">Scientific writing of CMC/clinical/non-clinical data/ Modules</a></h3>
               <h3 class="mb-3 "> <a href="#">CTA/IMPD/IND writing</a></h3>
               </ul>
             </li>
             <li class="End-to-end">
             <h3 class="mb-3 ">  <a href="#">End To End Compliannce</a></h3>
               <ul class="End-to-end-submenu">
               <h3 class="mb-3 ">   <a href="#">Site Due diligence</a></h3>
               <h3 class="mb-3 ">  <a href="#">Liasing with agency on inspection</a> </h3>  
               <h3 class="mb-3 "> <a href="#">Third Party/QP audits</a></h3>
               <h3 class="mb-3 ">  <a href="#">SOP preparation</a></h3>
               <h3 class="mb-3 "> <a href="#">GMP audit/CAPA services</a></h3>
               <h3 class="mb-3 ">  <a href="#">EU GMP Part I/II compliance and mock audit</a></h3>
               <h3 class="mb-3 "> <a href="#">21 CFR Part 11/Annex 11 compliance</a></h3>
               <h3 class="mb-3 ">  <a href="#">End to End GMP support and consulting</a></h3>
               <h3 class="mb-3 ">  <a href="#">Onsite/online GMP Training</a></h3>
               </ul>
             </li>
             <li class="Regulatory">
             <h3 class="mb-3 ">  <a href="#">Clinical & Non-Clinical</a></h3>
               <div class="submenu1">
               
                 <ul class="mega-links">
                   <li class="sub-submenu">
                   <h3 class="mb-3 ">  <a href="#">Regulatory Services</a></h3>
                     <ul class="EUsubmenu">
                     <h3 class="mb-3 ">     <a> Medical Writing Services</a></h3>
                     <h3 class="mb-3 "> <a> CTD Clinical Overviews (Module 2.5) and Non-Clinical Overviews(Module 2.4)</a></h3>
                     <h3 class="mb-3 "> <a> Clinical and Non-clinical Summaries (Module 2.5 and Module 2.6)</a></h3>
                     <h3 class="mb-3 ">  <a> Expert Statement</a></h3>
                     <h3 class="mb-3 "> <a> Preparation of Module 4 and Module 5</a></h3>
                     <h3 class="mb-3 ">  <a> Literature based feasibility check</a></h3>
                     <h3 class="mb-3 "> <a> Biowaiver justifications</a></h3>
                     <h3 class="mb-3 "> <a> Response to clinical/non clinical deficiencies</a></h3>
                     <h3 class="mb-3 ">  <a> Clinical/non-clinical development strategy for MAA</a></h3>
                     <h3 class="mb-3 "> <a> PSURs, Risk Management Plans</a></h3>
                     <h3 class="mb-3 "> <a> Clinical /non-clinical due diligence</a></h3>




                     </ul>
                   </li>
                 
                   <li class="sub-submenu">
                   <h3 class="mb-3 ">    <a href="#">Bioequivalence/Bioavailability Study Support</a></h3>
                     <ul class="EUsubmenu">
                      
                     <h3 class="mb-3 ">   <a> Clinical Study Design</a></h3>
                     <h3 class="mb-3 ">  <a> Synopsis/Protocol Writing</a></h3>
                     <h3 class="mb-3 "> <a> Local Clinical Study Monitoring</a></h3>
                     <h3 class="mb-3 "> <a> Site monitoring activities –CRF review and site monitoring report preparation</a></h3>
                     <h3 class="mb-3 ">  <a> Site training for protocol, CRF filling, SOPs etc</a></h3>
                     <h3 class="mb-3 ">  <a> EC training activities and auditing in India</a></h3>
                     <h3 class="mb-3 "> <a> Audit preparation support and mock audit Globally</a></h3>
                     <h3 class="mb-3 "> <a> Designing and writing clinical trial protocols</a></h3>
                     <h3 class="mb-3 "> <a> Clinical Data Management</a></h3>
                    




                     </ul>
                   </li>
                   <li class="sub-submenu">
                   <h3 class="mb-3 "> <a href="#">Toxicology Support</a></h3>
                     <ul class="EUsubmenu">
                      
                     <h3 class="mb-3 ">  <a> Toxicology assessment</a></h3>
                     <h3 class="mb-3 ">  <a> Data review</a></h3>
                     <h3 class="mb-3 ">  <a> Pre-clinical Development strategy</a></h3>
                     <h3 class="mb-3 ">  <a> ICH M7 Risk Assessment</a></h3>
                     <h3 class="mb-3 "> <a> QSAR Prediction</a></h3>
                      



                     </ul>

                   </li>
              
                   
                 </ul>
               
               </div>
               
             </li>
             <li class="Solutions">
             <h3 class="mb-3 ">   <a href="#">Solution For CROs/CMOs</a></h3>
               <ul class="Solutions-submenu">
               <h3 class="mb-3 ">  <a href="#">Development Due Diligence for US FDA/EU/EMA/MHRA</a></h3>
               <h3 class="mb-3 ">  <a href="#">End to End regulatory review support and advice during the development</a>  </h3> 
               <h3 class="mb-3 ">  <a href="#">Review/preparation of formulation development report from raw data based on ICH/EMA</a></h3>
               <h3 class="mb-3 ">  <a href="#">Review of analytical Development documentation</a></h3>
               <h3 class="mb-3 ">   <a href="#">Review of Method validation protocols/report</a></h3>
               <h3 class="mb-3 ">  <a href="#">Review of Master manufacturing records/batch Records/Packing Records</a></h3>
               <h3 class="mb-3 "> <a href="#">Review of Specifications</a></h3>
               <h3 class="mb-3 ">  <a href="#">DMF review and advice</a></h3>
               <h3 class="mb-3 "> <a href="#">Scientific strategies while development</a></h3>
               <h3 class="mb-3 ">  <a href="#">Reference product section/RLD review</a></h3>
               <h3 class="mb-3 ">  <a href="#">BE study waiver opinion/study design</a></h3>
               <h3 class="mb-3 ">  <a href="#">Offsite/online review and training to CROs and CMOs</a></h3>
             </ul>
             </li>
             <li class="Pharmacovigilance">
             <h3 class="mb-3 ">   <a href='#'>Pharmacovigilance</a></h3>
               <ul class="Pharmacovigilance-submenu">
               <h3 class="mb-3 ">  <a href="#">Periodic Safety Update Reports (PSUR)</a></h3>
               <h3 class="mb-3 ">  <a href="#">Risk Management Plan (RMP) writing</a>   </h3>
               <h3 class="mb-3 ">  <a href="#">Review of safety updates</a></h3>
               <h3 class="mb-3 "> <a href="#">ICSR</a> </h3>
               <h3 class="mb-3 "> <a href="#">Narrative writing</a></h3>
               <h3 class="mb-3 ">  <a href="#">Literature search</a></h3>
               <h3 class="mb-3 "> <a href="#">SAE – collection of data from sites, preparing final documents/dossiers, submission to DCGI</a></h3>
               <h3 class="mb-3 "> <a href="#">Local QPPV availability for India</a></h3>
               <h3 class="mb-3 ">  <a href="#">Training and establishment of team</a></h3>
               </ul>
             </li>
             <li class="Labelling-solutions">
             <h3 class="mb-3 ">  <a href='#'>Labelling Solutins</a></h3>
               <ul class="Labelling-solutions-submenu">
               <h3 class="mb-3 "> <a href="#">Cretaion of prescribing information according to regional requirements EU/FDA/CANADA/PICS</a></h3>
               <h3 class="mb-3 ">  <a href="#">Creation packaging/patient information leaflet leaflet</a>   </h3>
               <h3 class="mb-3 "> <a href="#">Creation of Labelling text/packging informtion</a></h3>
               <h3 class="mb-3 ">  <a href="#">Review and advice on PIL/SmPC</a></h3>
               <h3 class="mb-3 "> <a href="#">SmPC harmonization/safety update</a></h3>
               <h3 class="mb-3 ">  <a href="#">Impact analysis of safety changes</a></h3>   
               <h3 class="mb-3 ">  <a href="#">Assistance with EMA QRD template udate</a></h3>
               <h3 class="mb-3 ">  <a href="#">SPL publishing</a></h3>
               <h3 class="mb-3 ">  <a href="#">Artwork/mock up preparation</a></h3>
               <h3 class="mb-3 ">  <a href="#">Assistance with EMA linquistic review process</a>  </h3> 
               <h3 class="mb-3 ">  <a href="#">Serialization/track and trace solutions</a></h3>
               <h3 class="mb-3 ">  <a href="#">Translations to EU languages</a></h3>
               <h3 class="mb-3 ">  <a href="#">Expert advice related to packaging development</a></h3>
               </ul>
             </li>
           </ul>
         </div>
        
      
       </div>





  </div>
</div>




</div>
<div class="col-sm-1"></div>
       </div>
     </div>















  <h3 class="mb-3">Training</h3>
  <h3 class="mb-3">News & Blogs</h3>
  <h3 class="mb-3">Insights</h3>
  <h3 class="mb-3">Contact</h3>
  </div></div>
</div>
                  </ul>
              </div>
     
             
          
            
          </nav>
      </div>
  </header> 
    
    </>
  )
}

export default Navbar