import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Training() {
  return (
  <>
   <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Training

           </h2>
          <p>
          Corporate training has become one of the essentials in the company . Corporate training can help improve the efficiency of a company. It helps find out the weekness of a company and develop it to bring success. We here at Silmed Scientific are providing training to the companies so that all employees can have the chance to improve themselves.
          </p>
           
       </div>
   </div> 
   <div class="container-fluid mg1 py-5">

        <div class="container pb-5">
      <center><h1 class="display-6 mb-3">Advantages of training</h1></center>  <br></br>
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid timg" src="./ass/img/t1.png" alt=""/><br></br><br></br>
<h3>Increased Efficiency</h3>
<p class="mb-4">Specific and well-designed training can fulfill the needs of the workforce, make required improvements and boost the level of efficiency.Effective training programs provide innumerable opportunities for learning to the employees
 </p>




                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                 
                <img class="img-fluid timg" src="./ass/img/t2.png" alt=""/><br></br><br></br>
<h3>High Employee Performance
</h3>
<p class="mb-4">Corporate learning and training helps an organization to create an ideal employee with high employee performance and productivity. Providing training and learning opportunities in the workplace is one of the best ways to keep the employees engaged
 </p>
                   
                   
                  
  
  
  </div></div>
  
 {/* 2  */}
  
 <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <center>  <img class="img-fluid timg" src="./ass/img/t3.png" alt=""/></center><br></br><br></br>
<h3>Identify Weaknesses</h3>
<p class="mb-4">Corporate learning and training offers the framework to develop strengths. It helps to identify weaknesses and make suitable amendments through the required corporate learning and training programs
 </p>




                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                 
                <img class="img-fluid timg" src="./ass/img/t4.png" alt=""/><br></br><br></br>
<h3>Gain Competitive Advantage
</h3>
<p class="mb-4">Continuous learning and evolving help the organization in gaining a competitive advantage over its competitors. It helps the employees in realizing that their successes, as well as the company’s growth, are interlinked
 </p>
                   
                   
                  
  
  
  </div></div>
  
  
  
  
  
  
  
  </div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  </>
  )
}

export default Training