import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Learning() {
  return (
    <>
     <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br><br></br>
     






     <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center py-5 mt-4">
            <h1 class="display-2 text-white mb-3 animated slideInDown">Learning
            </h1>
           {/* <p>
           Innovative services with huge experience in the global pharmaceutical environment Silmed Scientific deliver potential services and also plans to include innovative technologies to deliver some services, Services as product and process outsourcing services
           </p> */}
            
        </div>
    </div> 

    <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
      <center><h1 class="display-6 mb-3">Silmed Learning Portal</h1></center><br></br>  
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid img1" src="./ass/img/pharmaDoctorinaLogo.svg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Silmed Learning Portal</h1>
                 
                    <p class="mb-1">Pharma Doctorina, the innovative product of Silmed Learning, is the first of its kind e-learning platform solely dedicated to pharma and life science. The portal will function as a space where students, professionals, academicians and researchers can interact and share their knowledge through webinars, blogs on dynamic subjects and the latest research and development. Eligible professionals/ academicians can introduce their certificate courses on the platform by meeting the criteria and obtaining approval from our expert faculty and thus, can earn a regular income and recognition</p>
                    <br></br> <br></br><br></br> <br></br>
    <a href='/Regulatorya'><button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>  
                   
                </div>
            </div>
           
{/* second 2 */}
<center><h1 class="display-6 mb-3">Silmed Academy</h1></center><br></br>  
<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Center for Advanced Pharmaceutical Studies</h1>
                 
                 <p class="mb-1">CAPS is an initiative of pharmaceutical industry. We have identified the disciplined that help in advancement of the pharmaceutical profession. With a global aim to promote these disciplines and healthcare excellence, we have designed programs that help to develop the specific skills and bridge the gap between academics and industry</p>
                 <br></br> <br></br><br></br> <br></br>
                 <a href='/Apis'> <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid img1" src="./ass/img/capsLogo.png" alt=""/>
                  
                   
                </div>
            </div>
</div>
</div>














{/* 
<div class="container">
  <div class="row">
    <div class="col-sm-6">hi</div>
    <div class="col-sm-6">hi</div>
  </div>
</div> */}<div class="container-fluid py-5">
        <div class="container">
      <br></br>  <center>
<h2 class=" animated slideInDown">How We Help You To Climb The Ladder
           </h2></center><br></br>
  <div class="row g-0 feature-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/pharmacy.png"/><br></br><br></br>
                    
                        <p class="mb-0">Course certified by medical Pharmacology Society</p>
                   </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/planting.png"/><br></br><br></br>
                     
                        <p class="mb-0">Help to grow along the dynamics of pharmaceutical Industry </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/guidance.png"/><br></br><br></br>
                     
                        <p class="mb-0">Free Career Guidance</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/outstanding.png"/><br></br><br></br>
                     
                        <p class="mb-0">Eminent Faculty</p>
                    </div>
                </div>
            </div>



            <div class="row g-0 feature-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/flexible.png"/><br></br><br></br>
                    
                        <p class="mb-0">Flexible course Mode</p>
                   </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/online-course.png"/><br></br><br></br>
                     
                        <p class="mb-0">CAPS course are designed to satisfy the needs of both the student and industry</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/goal.png"/><br></br><br></br>
                     
                        <p class="mb-0">CAPS guides you to choose a glorious career to excel yourselves.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid lerimg" src="./ass/img/success.png"/><br></br><br></br>
                     
                        <p class="mb-0">CAPS moulds you as the twinkling stars in the dark sky of competitive job marketty</p>
                    </div>
                </div>
            </div>
</div></div>


    <Fcontact></Fcontact>
    <Footer></Footer>
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Learning