import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Labellings() {
  return (
  <>
  
  <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Labelling Solutions

           </h2>
          <p>
          One of the greatest challenge in pharmaceutical industry is patient compliance. Effective and novel packaging solutions can address this challenge to great extent. Compliance in labelling information is challenging. Harmonisation of product information is always another hurdle in EU. We provide complete packaging/labelling solutions for global markets, simplifying the complexities.
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/l1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">List Of Services</h1>
                    <p class="mb-4">● Cretaion of prescribing information according to regional requirements EU/FDA/CANADA/PICS<br></br>
                    ● Creation packaging/patient information leaflet leaflet<br></br>
                    ● Creation of Labelling text/packging informtion<br></br> 
                    ● Review and advice on PIL/SmPC<br></br>
                    ● SmPC harmonization/safety update<br></br>
                    ● Impact analysis of safety changes<br></br> 
                    ● Assistance with EMA QRD template udate<br></br>
                    ● SPL publishing<br></br>
                    ● Artwork/mock up preparation<br></br>
                    ● Assistance with EMA linquistic review process<br></br>
                    ● Serialization/track and trace solutions<br></br>
                    ● Translations to EU languages<br></br>
                    ● Expert advice related to packaging development<br></br>
                    </p>
  
  
  </div></div></div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  
  
  
  
  
  
  </>
  )
}

export default Labellings