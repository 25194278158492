import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Globalcoverage() {
  return (
    <>
     <Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
    <div className="globalcoveragevid">
      <h1>Global Coverage</h1>
      <video playsInline muted autoPlay loop>
        <source src="./ass/img/Earth_Rotation.mp4" type="video/mp4" />
      </video>
    </div>
<div class="globalcoveragecont">
     <p>We Provide Services to the following regions as well:-</p>
     <ul>
          <li>European Union</li>
          <li>United Kingdom</li>
          <li>United State</li>
          <li>CIS</li>
          <li>Latein America</li>
          <li>India</li>
          <li>ASEAN</li>
          <li>GCC</li>
          <li>China</li>
          <li>Australia</li>
          <li>Canada</li>
          <li>Switzerland</li>
          <li>Newzealand</li>
     </ul>
</div>
  <Fcontact></Fcontact>  
  <Footer></Footer>
    
    
    
    
    
    
    
    
    </>
  )
}

export default Globalcoverage