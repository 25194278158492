import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Careers5() {
  return (
<>
<Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
<div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">International Business Development Executive</h1></center><br></br>
<center><p class= "cr1">We are hiring International Business Developers who are qualified to extend our global reach through expert discovery and exploration of new and untapped business opportunities and relationships.Business development executives are responsible for finding and retaining clients, encouraging extant clients to purchase added products or features, and remaining abreast of changes in consumption.</p></center><br></br>
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/c51.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Responsibilities</h1>
                    <p class="mb-4">● Perform business development activities in pharma or Medical Device domain<br></br>
                    ● Ability to bring leads from international market place<br></br>
                    ● Generate leads & convert into projects
<br></br> 
                    ● Experience in international business developmnt<br></br>
                    ● Fluency in German or French preferred<br></br>
               
                 
                   
                    </p>
  
  
  </div></div>
  
  <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Requirements</h1>
                <p class="mb-4">● BVSC - Any Specialization/ BHM - Any Specialization/ BHMS - Any Specialization/ B.Pharma - Pharmacy/ BAMS - Any Specialization/ Other Graduate - Other Specialization<br></br>
                    ● MS/M.Sc(Science) - Biotechnology/ Agriculture/ Bio-Chemistry/ Biology/ Botany/ Chemical Engineering & Materials Science/ Chemistry/ M.Pharma - Any Specialization/ M.Tech - Environmental/ Instrumentation/ Bio-Chemistry/Bio-Technology/ Biomedical/ Chemical<br></br>
                    ● Familiar in Pharma international market, R &D and Regulatory Processes<br></br> 
                    ● Prior experience in Regulatory affairs or licensing Pharmaceutical products or medical devices.<br></br>
                    ● Excellent negotiation skills<br></br>
                   
                    </p>
               
              
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/c52.jpg" alt=""/>
                  
                   
                </div>
            </div>
  
  
  
  
  
  
  </div></div>
 <center> <a href='/Contact'>     <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a></center><br></br><br></br>
  <Fcontact></Fcontact>
 <Footer></Footer>




</>
  )
}

export default Careers5