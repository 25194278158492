import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights10() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container mg1 py-5">
<center><h1 class="display-6 mb-3">Expiration Date Of Unit-Dose Repackaged Solid Oral Dosage Form Drug Products</h1></center><br></br>
<div class="container"><p class= "cr1">In last few decades there has been increase in demand for unit dosage pack. Thus the bulk packed solid orals are repacked for single dose packs. The increase in unit-dose repackaging has led to questions regarding stability studies and appropriate expiration dates for these repackaged products. Usually the containers used for stability study should be used for marketing pack as well, as it will ensure drug products safety efficacy over intended shelf life.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i9.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">
     For unit-dose repackaged products, USP General Chapter Good Repackaging Practices recommends that the expiration date not exceed  <br></br>
     • 6 months from the date of repackaging   <br></br>
     • the manufacturer’s expiration date  <br></br>
     • 25% of the time between the date of repackaging and the expiration date shown on the manufacturer’s bulk article container of the drug being repackaged, whichever is earlier.
     <br></br><br></br><br></br>   
     Unit dose repacking container has to be class A or Class B based on moisture sensitivity of drug product. The package should protect the drug from light. The drug products original container should be opened and repacked at once. Repackaging and storage should happen in the same environmental condition mentioned in the label of bulk pack.  
        
        </p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> expiration dating of unit-dose repackaged solid oral dosage form drug products guidance for industry, july 2020 pharmaceutical quality/manufacturing standards (cgmp)</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights10