import React, { useEffect } from 'react';

// import './YourComponent.css'; 
import {BrowserRouter as Router,Link,NavLink,Route,Routes} from "react-router-dom";
import Home from './Page/Home';
import Webiner from './Page/Webiner';
import Fcontact from './Page/Fcontact';
import Footer from './Page/Footer';
import Careers from './Page/Careers';
import Globalcoverage from './Page/Globalcoverage.js';
import Loading from './Page/Loading';
import Learning from './Page/Learning';
import About from './Page/About';
import Services from './Page/Services';
import Regulatorya from './Page/Regulatorya';
import Apis from './Page/Apis';
import Scientifics from './Page/Scientifics';
import Endtoend from './Page/Endtoend';
import Clinicalnc from './Page/Clinicalnc';
import Solutioncc from './Page/Solutioncc';
import Pharmacovigilance from './Page/Pharmacovigilance';
import Labellings from './Page/Labellings';
import Training from './Page/Training';
import Contact from './Page/Contact';
import Insights from './Page/Insights';
import News from './Page/News';
import Careers1 from './Page/Careers1';
import Careers2 from './Page/Careers2';
import Careers3 from './Page/Careers3';
import Careers4 from './Page/Careers4';
import Careers5 from './Page/Careers5';
import Insights1 from './Page/Insights1';
import Insights2 from './Page/Insights2';
import Insights3 from './Page/Insights3';
import Insights4 from './Page/Insights4';
import Insights5 from './Page/Insights5';
import Insights6 from './Page/Insights6';
import Insights7 from './Page/Insights7';
import Insights8 from './Page/Insights8';
import Insights9 from './Page/Insights9';
import Insights10 from './Page/Insights10';
import Insights11 from './Page/Insights11';
import Insights12 from './Page/Insights12';
import Insights13 from './Page/Insights13';
import Podcast from './Page/Podcast';
import Product from './Page/Product.js';
function App() {
  
  return (
  <>
  
  <Router>
    <>
  
 

{/* <Navbar></Navbar> */}


    <Routes><Route exact path={"/"} element={<Home></Home>}></Route>  
    {/* <Route path={"/Home"} element={<Home></Home>}></Route> */}
    <Route path={"/Webiner"} element={<Webiner></Webiner>}></Route>
    <Route path={"/Fcontact"} element={<Fcontact></Fcontact>}></Route>
    <Route path={"/Footer"} element={<Footer></Footer>}></Route>
    <Route path={"/Careers"} element={<Careers></Careers>}></Route>
    <Route path={"/Globalcoverage"} element={<Globalcoverage></Globalcoverage>}></Route>
    <Route path={"/Learning"} element={<Learning></Learning>}></Route>
    <Route path={"/About"} element={<About></About>}></Route>
    <Route path={"/Services"} element={<Services></Services>}></Route>
    <Route path={"/Regulatorya"} element={<Regulatorya></Regulatorya>}></Route>
    <Route path={"/Apis"} element={<Apis></Apis>}></Route>
    <Route path={"/Scientifics"} element={<Scientifics></Scientifics>}></Route>
    <Route path={"/Endtoend"} element={<Endtoend></Endtoend>}></Route>
    <Route path={"/Clinicalnc"} element={<Clinicalnc></Clinicalnc>}></Route>
    <Route path={"/Solutioncc"} element={<Solutioncc></Solutioncc>}></Route>
    <Route path={"/Pharmacovigilance"} element={<Pharmacovigilance></Pharmacovigilance>}></Route>
    <Route path={"/Labellings"} element={<Labellings></Labellings>}></Route>
    <Route path={"/Training"} element={<Training></Training>}></Route>
    <Route path={"/Contact"} element={<Contact></Contact>}></Route>
    <Route path={"/Insights"} element={<Insights></Insights>}></Route>
    <Route path={"/News"} element={<News></News>}></Route>
    <Route path={"/Careers1"} element={<Careers1></Careers1>}></Route>
    <Route path={"/Careers2"} element={<Careers2></Careers2>}></Route>
    <Route path={"/Careers3"} element={<Careers3></Careers3>}></Route>
    <Route path={"/Careers4"} element={<Careers4></Careers4>}></Route>
    <Route path={"/Careers5"} element={<Careers5></Careers5>}></Route>
    <Route path={"/Insights1"} element={<Insights1></Insights1>}></Route>
    <Route path={"/Insights2"} element={<Insights2></Insights2>}></Route>
    <Route path={"/Insights3"} element={<Insights3></Insights3>}></Route>
    <Route path={"/Insights4"} element={<Insights4></Insights4>}></Route>
    <Route path={"/Insights5"} element={<Insights5></Insights5>}></Route>
    <Route path={"/Insights6"} element={<Insights6></Insights6>}></Route>
    <Route path={"/Insights7"} element={<Insights7></Insights7>}></Route>
    <Route path={"/Insights8"} element={<Insights8></Insights8>}></Route>
    <Route path={"/Insights9"} element={<Insights9></Insights9>}></Route>
    <Route path={"/Insights10"} element={<Insights10></Insights10>}></Route>
    <Route path={"/Insights11"} element={<Insights11></Insights11>}></Route>
    <Route path={"/Insights12"} element={<Insights12></Insights12>}></Route>
    <Route path={"/Insights13"} element={<Insights13></Insights13>}></Route>
    <Route path={"/Podcast"} element={<Podcast></Podcast>}></Route>
    <Route path={"/Product"} element={<Product></Product>}></Route>
   </Routes> 
   </>
</Router>
 
    



  
  
  </>
  );
}

export default App;
