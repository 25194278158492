import React from 'react'
import Navbar from '../Navbar';
function Webiner() {
  return (
   <>
   <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
     <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center py-5 mt-4">
            <h1 class="display-2 text-white mb-3 animated slideInDown">Webiner</h1>
            <nav aria-label="breadcrumb animated slideInDown">QualityCheck
               
            </nav>
        </div>
    </div>
<br></br><br></br><br></br>
<div class="container wow fadeIn" data-wow-delay="0.6s"> 
<input type="radio" id="reset" name="color"/>
  <label for="reset">All</label>
  <input type="radio" id="blue" name="color" />
  <label for="blue">Pharma&Biotech</label>
  <input type="radio" id="red" name="color"/>
  <label for="red">Medical Devices</label>
  <input type="radio" id="green" name="color"/>
  <label for="green">Annx1</label>
  <br></br><br></br>

  <div class="tile blue">
  <img class="img-fluid weby" src="./ass/img/about-1.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p>







  </div>
  <div class="tile red"><img class="img-fluid weby" src="./ass/img/about-3.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p></div>
  <div class="tile blue">

  <img class="img-fluid weby" src="./ass/img/about-1.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p>






  </div>
  <div class="tile green"><img class="img-fluid weby" src="./ass/img/about-2.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p></div>

  <div class="tile red"><img class="img-fluid weby" src="./ass/img/about-3.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p></div>
  <div class="tile red"><img class="img-fluid weby" src="./ass/img/about-3.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p></div>
  <div class="tile green"><img class="img-fluid weby" src="./ass/img/about-2.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p></div>
  <div class="tile blue">
  <img class="img-fluid weby" src="./ass/img/about-1.jpg"/>
<h4 class="wtext">Strategic Marketing for CDMO Industry</h4>
<p>&nbsp; &nbsp; &nbsp;<i class="fa-solid fa-calendar-days"></i> 9, October 2023</p>



  </div>

</div>
   
   
   
   
   </>
  )
}

export default Webiner