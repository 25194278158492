import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Careers4() {
  return (
<>
<Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
<div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Manager Regulatory Affairs US and European Market</h1></center><br></br>
<center><p class= "cr1">We are hiring dynamic regulatory affairs talents with capabilities in CMC and regulatory intelligence.</p></center><br></br>
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/c41.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Responsibilities</h1>
                    <p class="mb-4">● Direct global clinical, non-clinical, and CMC regulatory strategies.<br></br>
                    ● Preparation of submission dossiers in CTD/eCTD for US FDA and EU.<br></br>
                    ● CMC writing/review, EU/ANDA/NDA/IND submissions.
<br></br> 
                    ● Development/regulatory driven development plan on clients products.<br></br>
                    ● Experience with other key markets like CANADA and China desirable<br></br>
               
                 
                   
                    </p>
  
  
  </div></div>
  
  <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Requirements</h1>
                <p class="mb-4">● M.Pharma - Other Specialization/ MS/M.Sc(Science) - Bio-Chemistry/ Medical-MS/MD - Any Specialization/Ph.D/Doctorate - Any Specialization<br></br>
                    ● Ph.D/Doctorate - Any Specialization<br></br>
                    ● BVSC - Any Specialization/ BHM - Any Specialization/ BHMS - Any Specialization/ B.Pharma - Pharmacy/ BAMS - Any Specialization/ Other Graduate - Other Specialization<br></br> 
                    ● MS/M.Sc(Science) - Biotechnology/ Agriculture/ Bio-Chemistry/ Biology/ Botany/ Chemical Engineering & Materials Science/ Chemistry/ M.Pharma - Any Specialization/ M.Tech - Environmental/ Instrumentation/ Bio-Chemistry/Bio-Technology/ Biomedical/ Chemical<br></br>
                    ● Author, review and edit regulatory documents and packages for submissions to national regulatory authorities (NRAs) and/or scientific or executive management meetings<br></br>
                    ● Serve as the lead writer for high priority submissions such as Scientific advice meeting briefing materials/PIP/IMPD/IND submissions and amendments<br></br> 
                    
                    ● Involvement in Training and development.<br></br> 
                    ● Experience in core regulatory function for US/EU/CANADA markets is preferred.<br></br>
                    ● Detail oriented person, with strong capability to multi-task<br></br>
                    
                    ● Experience with developmental CMC of solids /sterile injectables/ eyedrops /respiratory products<br></br> 
                    ● Involve in training and development activities as and when required.<br></br>
                    ● Participate in client discussions<br></br>
                    </p>
               
              
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/c42.jpg" alt=""/>
                  
                   
                </div>
            </div>
  
  
  
  
  
  
  </div></div>
 <center> <a href='/Contact'>     <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a></center><br></br><br></br>
  <Fcontact></Fcontact>
 <Footer></Footer>




</>
  )
}

export default Careers4