import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Clinicalnc() {
  return (
   <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Clinical & Non-Clinical
           </h2>
          <p>
          Our clinical /non-clinical experts have more than 20 years of experience working with different Clinical Research Organisations (CROs) and multinational pharmaceutical companies. These resources are equipped to provide you solutions to global drug development strategy.
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/cl1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Regulatory Services</h1>
                    <p class="mb-4">● Medical Writing Services<br></br>
                    ● CTD Clinical Overviews (Module 2.5) and Non-Clinical Overviews(Module 2.4)<br></br>
                    ● Clinical and Non-clinical Summaries (Module 2.5 and Module 2.6)<br></br> 
                    ● Expert Statement<br></br>
                    ● Preparation of Module 4 and Module 5<br></br>
                    ● Literature based feasibility check<br></br>
                    ● Biowaiver justifications<br></br>
                    ● Response to clinical/non clinical deficiencies<br></br>
                    ● Clinical/non-clinical development strategy for MAA<br></br>
                    ● PSURs, Risk Management Plans<br></br>
                    ● Clinical /non-clinical due diligence<br></br>
                  
                    </p>
                  
                  
      {/* <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button>
                    */}
                </div>
            </div>
           
{/* second 2 */}
<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Bioequivalence/Bioavailability Study Support</h1>
                <p class="mb-4">● Clinical Study Design<br></br>
                    ● Synopsis/Protocol Writing<br></br>
                    ● Local Clinical Study Monitoring<br></br> 
                    ● Site monitoring activities –CRF review and site monitoring report preparation<br></br>
                    ● Site training for protocol, CRF filling, SOPs etc<br></br>
                    ● EC training activities and auditing in India<br></br>
                    ● Audit preparation support and mock audit Globally<br></br>
                    ● Designing and writing clinical trial protocols<br></br>
                    ● Clinical Data Management<br></br>
                    </p>
               
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/cl2.jpg" alt=""/>
                  
                   
                </div>
            </div>
{/* therd 3 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/cl3.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Toxicology Support</h1>
                    <p class="mb-4">● Toxicology assessment<br></br>
                    ● Data review<br></br>
                    ● Pre-clinical Development strategy<br></br> 
                    ● ICH M7 Risk Assessment  <br></br>
                    ● QSAR Prediction<br></br>
                   
                  
                  
                    </p>
                 
                </div>
            </div>









        </div>
    </div>
   
   <Fcontact></Fcontact>
   
   <Footer></Footer>
   
   
   
   
   
   
   </>
  )
}

export default Clinicalnc