import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights11() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Scientific Advice</h1></center><br></br>
<div class="container"><p class= "cr1">Scientific advice is when a medicine developer requests advice from the European Medicines Agency (EMA) for a medicine they are developing. This advice can be requested at any stage of development like manufacturing, clinical study and methodology issues etc.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i10.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Patients are asked to share the experience and real life perspective to particular medicine in their disease area. This helps developer and regulator to get better understanding on the patients needs. Patient input resulted in modification of advice to company — 20% and Reflection by coordinators —53%. The Committee for Medicinal Products for Human Use (CHMP) is responsible for scientific advice, which is based on recommendations from the Scientific Advice Working Party (SAWP).
     <br></br>   <br></br>   <br></br>
     Patients are contacted by EMA or through an EMA eligible patient organization. If EMA decides to respond to the medicine developer in writing, patients are asked to provide comments (Day 40). If EMA decides to meet the medicine developer, the patient will be invited to attend (in person or via conference call) at Day 60. Travel and accommodation is organized and covered by EMA. After participating, patients are sent a copy of the advice letter sent to the medicine developer, minutes (where a meeting has taken place) and a link to a questionnaire for feedback on their overall experience.
     
     </p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  {/* <p> <i> bispecific antibody development programs guidance for industry, may 2021 pharmaceutical quality/cmc</i></p> */}
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights11