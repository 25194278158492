import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function About() {
  return (
    <>
     <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
     <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center py-5 mt-4">
            <h2 class="display-5 text-white mb-3 animated slideInDown">Enabling Global Market access of Safe Medicines.
            </h2>
           <p>
           Silmed Scientific is an initiative of Pharmaceutical Professionals who have long years of experience in the global pharmaceutical industry.We provide end to end solutions and advice to companies for the development and approval of safe medicines in international markets.We bring 20 years of excellence in global regulatory affairs and have a proven track record in promoting global access of medicine through scientific support to Pharmaceutical development, manufacturing compliance, packaging, and commercialization of medicines.
           </p>
            
        </div>
    </div> 
    
    <div class="container-fluid mg py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/b8.png" alt=""/>
                </div>
                <div class="col-md-6  wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">
Innovate, ignite, Analyse, Together for Market Access</h1>
                   
                    <p class="mb-1">Shortest distance between two points is a straight-line according to Euclidean Geometry". Experts at Silmed Scientific are determined to bridge the dossier gap.Specific skills are required for research and development, manufacturing, and successful commercialization and are the key to the innovation in the pharmaceutical/healthcare sector.Our experts are leaders in the pharmaceutical industry and their experience and skill set will help successful market access.We work with global pharmaceutical companies to register their product</p>
           
                   
                </div>
            </div>
           
        </div>
    </div>
   <center><h1 class="display-6 mb-3">Our Team</h1></center> 
    <div class="container-fluid mg py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/lini_photo1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Lini Subin</h1>
                    <p class="mb-1"><font color='red'>CEO/ Senior Regulatory Affairs Consultant</font></p>
                    <p class="mb-1">Lini is an Accomplished regulatory affairs professional with two decades of experience in End-to-End Regulatory Process & Requirements. She provides leadership within the area of complex medicinal products in Europe, including guidance on product development and regulatory strategy. Supported nearly 300 international companies in different regulatory affairs activities and she is able to support regulatory submission activities for any global markets.</p>
                  <br></br> <p class="mb-1">Her Specialties include.</p>
                    <p class="mb-4">● Strategical Advice on EU Registration procedures (DC/MR, CP and national)<br></br>
                    ● CMC Dossier Development & writing<br></br>
                    ● Regulatory Strategy - Global strategy for Generic Drugs, New Drugs, Generic fixed dose combinations & biosimilars/biologics<br></br> 
                    ● Regulatory Dossier Due Diligence<br></br>
                    ● Implementation and maintenance of electronic dossier tools<br></br>
                    ● Scientific advice meetings, Briefing packages and controlled correspondence with the regulators<br></br>
                    ● Dossier development for innovative drugs<br></br>
                    ● EU Lifecycle maintenance – All types of variations, renewal, update of product information based on safety updates<br></br>
                    ● CTD/NeeS/eCTD Compilation of dossiers<br></br>
                    ● Review and publishing in electronic format (eCTD)<br></br>
                    </p>
                   
                </div>
            </div>
           
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <h2 class="mb-4">Senior Medical Officer</h2>
                    <p><font color='red'>Clinical Affairs</font></p>
                    <p class="mb-4">He brings 21+ years of experience in the Department of Clinical Pharmacology and Drug development with hands on experience in different areas such as Clinical operation (BA/BE studies and Clinical trials 1-4 phases), Pharmacovigilance, Regulatory and Quality management. He worked in reputed companies such as Novo Nordisk, Lotus lab (a Teva group of company), Jubilant Life sciences, Quartesian Clinical Research, Fortis Clinical Research, Notrox Research, Semler Research Centre etc.<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br></p>
                    
                    <hr/>
                 
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <h2 class="mb-4">Non-Clinical Expert</h2>
                    <p><font color='red'>PhD Biochemistry</font></p>
                    <p class="mb-4">Over 30 years of experience as a toxicologist and leader in discovery and development of new chemical entities in pharmaceutical industries. Experienced in the development of preclinical program, study design, conduct, reporting and evaluating efficacy/safety data for new chemical entities, biotechnology products, impurities in drug substance/products, design and execution of bridging toxicology studies for 505 (b) (2) NDA filings; in training and managing staff; in dealing with regulatory bodies, contract research organizations; in writing non-clinical sections of IND/CTA applications, material Safety Data Sheets, expert assessment and preparation of occupational exposure limit (OEL) and Acceptable daily intake (ADI)/Permissible Daily Exposure (PDE) limit values for pharmaceutical manufacturing and cleaning validation and in developing and managing operations and capital budgets.</p>
                    
                    <hr/>
                 
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <h2 class="mb-4">Senior Clinical/Medical Expert</h2>
                    <p><font color='red'>MD, PhD Organic Chemistry & Biochemistry, PhD Pharmacology</font></p>
                    <p class="mb-4">Extensive experience with and thought leadership on a range of Pharmaceutical operations (marketing, medical, compliance, clinical development, market access);Direct accountability for preparation of successful marketing and medical strategies and tactics and their implementation at both the local Pharma market and the Region Central & Eastern Europe;Comprehensive experience in clinical research that includes protocol writing, CT feasibility, roles and responsibilities of Local Study Responsible, Country Study Manager, carrying out medical monitoring and monitoring in selected clinical trials, preparation of clinical centers for audits both locally in Europe and internationally driven.</p>
                    
                    <hr/>
                 
                </div>
            </div>
        </div>
    </div>
     <Fcontact></Fcontact>
    <Footer></Footer>
    
    
    
    
    </>
  )
}

export default About