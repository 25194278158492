import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights() {
  return (
 <>
   <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

   {/* <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Scientific Services
           </h2>
          <p>
          Our scientific support services start from Pre-Development planning and support throughout the assessment phase. We think strategically and use regulatory wisdom and analyse the regulatory, quality, safety, and efficacy situations from an assessor perspective. Our experts are having extensive experience working on requirements for global agencies like USFDA, EMA, MHRA, MEB, TGA, etc. Our strategic thinking helps to effective planning and the fastest approval of the regulated products. We provide scientific support is not limited to complex dosage forms, biologics/biosimilars, fixed dose combinations. Early stage due diligence and discussion/debate with global agencies are required for advancing development and successful marketing authorization of the complex products.
          </p>
           
       </div>
   </div> */}




   <div class="container-fluid py-5">
        <div class="container">
        <center>
        <h1 class="mb-3"> Insights<br></br>
        </h1></center>
            <div class="row g-0 feature-row">
              <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i1.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Alternate Electronic Formate</h5><br></br> 
                        <p class="mb-0">An alternate electronic format for submissions is covered under an exemption from or a waiver of</p>
                        <br></br>
                      <center><a href='/Insights1'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                   </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i2.jpg"/><br></br><br></br>
                        <h5 class="mb-3">BCS Based Biowaiver</h5><br></br> <br></br> 
                        <p class="mb-0">Two drug products containing the same drug substance or substances are considered </p>
                        <br></br> <br></br>
                      <center><a href='/Insights2'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i3.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Bispecific Antibody Development Programs</h5>
                        <p class="mb-0">This guidance provides recommendations to assist industry and other stakeholders involved</p>
                        <br></br>
                      <center><a href='/Insights3'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/b3.png"/><br></br><br></br>
                        <h5 class="mb-3">Controlled Correspondence Related To Generic...</h5>
                        <p class="mb-0">This guidance suggests regarding the process by which generic drug manufacturers and related</p>
                        <br></br>
                      <center><a href='/Insights4'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
            </div>








            <div class="row g-0 feature-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i4.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Management And Classification Of Reports Of ....</h5>
                        <p class="mb-0">The procedure was revised in order to provide more comprehensive guidance following quality</p>
                        <br></br>
                      <center><a href='/Insights5'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i5.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Nitrosamine Impurity</h5><br></br><br></br>
                        <p class="mb-0">Good Manufacturing Practices (GMP, also referred to as 'cGMP' or 'current Good ..</p>

                        <br></br>    <br></br>
                      <center><a href='/Insights6'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i6.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Pilot Program: EMA-FDA Parallel Scientific Advice For...</h5>
                        <p class="mb-0">The European Medicines Agency (EMA) and the U.S. Food and Drug Administration (FDA) of the</p>
                        <br></br>
                      <center><a href='/Insights7'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i7.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Guidance For Industry Post Marketing Adverse Event...</h5>
                        <p class="mb-0">FDA anticipates that during the pandemic, industry and FDA workforces may be reduced</p>
                        <br></br><br></br>
                      <center><a href='/Insights8'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
            </div>




            <div class="row g-0 feature-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i8.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Product Development</h5>
                        <p class="mb-0">Product development is to design a quality product and its manufacturing process to</p>
                        <br></br><br></br><br></br>
                      <center><a href='/Insights9'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i9.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Expiration Date Of Unit-Dose Repackaged Solid Oral Dosage...</h5>
                        <p class="mb-0">In last few decades there has been increase in demand for unit dosage pack. Thus the bulk </p>
                        <br></br>
                      <center><a href='/Insights10'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i10.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Scientific Advice</h5>
                        <p class="mb-0">Scientific advice is when a medicine developer requests advice from the European Medicines</p>
                        <br></br>  <br></br>
                      <center><a href='/Insights11'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/i11.jpg"/><br></br><br></br>
                        <h5 class="mb-3">Product Name Placement, Size, And Prominence In... </h5>
                        <p class="mb-0">The guidance gives clarity with respect to the requirements for product name placement, size,</p>
                        <br></br>
                      <center><a href='/Insights12'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                </div>
            </div>










            <div class="row g-0 feature-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div class="feature-item border h-100 p-5">
                    <img class="img-fluid" src="./ass/img/b8.png"/><br></br><br></br>
                        <h5 class="mb-3">Q1/Q2 Assessment</h5>
                        <p class="mb-0">Q1/Q2 is a term referring to inactive ingredient assessments in ANDAs. A proposed generic</p>
                        <br></br>
                      <center><a href='/Insights13'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a></center>  
                    </div>
                  
                </div>
            
             
            </div>












        </div>
    </div>





 <Fcontact></Fcontact>
 <Footer></Footer>
 </>
  )
}

export default Insights