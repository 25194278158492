import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function News() {
  return (
 <>
   <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

   <div class="container-fluid feature mt-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
            <div class="row g-0">
                <div class="col-lg-6 pt-lg-5">
                    <div class="bg-white p-5 mt-lg-5">
                        <h1 class="display-6 mb-4 wow fadeIn" data-wow-delay="0.3s">News</h1>
                        <p class="mb-4 wow fadeIn" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tellus augue, iaculis id elit eget, ultrices pulvinar tortor. Quisque vel lorem porttitor, malesuada arcu quis, fringilla risus. Pellentesque eu consequat augue.</p>
                        <div class="row g-5 pt-2 mb-5">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                               
                              
                                <h5 class="mb-3">Company News</h5>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                               
                                <h5 class="mb-3">Market Access</h5>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                            </div>
                        </div>
                        <a class="btn btn-primary py-3 px-5 wow fadeIn" data-wow-delay="0.5s" href="">Explore More</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row h-100 align-items-end">
                        <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                           
                        </div>
                        <div class="col-12">
                            <div class="gh p-5">
                                <div class="experience mb-4 wow fadeIn" data-wow-delay="0.3s">
                                   
                                 
                                </div>
                              
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><br></br><br></br>
<div class="container py-5">
    <center>
<h1 class="display-6 mb-4 wow fadeIn" data-wow-delay="0.3s">Blogs</h1></center>
<br></br>
    <div class="row g-4">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c1.jpg" alt=""/>
                          
                        </div>
                        <div class="text-center p-4">
                            <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c2.jpg" alt=""/>
                            {/* <div class="team-social">
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                <a class="btn btn-square btn-light mx-1" href=""><i class="fab fa-youtube"></i></a>
                            </div> */}
                        </div>
                        <div class="text-center p-4">
                        <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c3.jpg" alt=""/>
                           
                        </div>
                        <div class="text-center p-4">
                        <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="./ass/img/c4.jpg" alt=""/>
                           
                        </div>
                        <div class="text-center p-4">
                        <h6 class="mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                            <span><i class="fa-regular fa-calendar-days"></i> 5th July 2023</span>
                        </div>
                    </div>
                </div>
            </div>

            </div>













 <Fcontact></Fcontact>
 <Footer></Footer>
 </>
  )
}

export default News