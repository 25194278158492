import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Product() {
  return (
<>
<Navbar></Navbar>
<br></br><br></br><br></br><br></br>
<button class="btn btnbg1 btnbg">
<div class="dropdown">
  <h7 class="shoptext">ProductInformation <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>



</button>
<button class="btn btnbg2 btnbg">
<div class="dropdown">
  <h7 class="shoptext">Templates <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>





</button>
<button class="btn btn-warning btnbg">
<div class="dropdown">
  <h7 class="shoptext">Modules Draft <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>
</button>
<button class="btn btnbg3 btnbg">
<div class="dropdown">
  <h7 class="shoptext">Book Store <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>
</button>
<button class="btn btnbg4 btnbg">
<div class="dropdown">
  <h7 class="shoptext">Buy Service <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>
</button>
<button class="btn btn-info btnbg">
<div class="dropdown">
  <h7 class="shoptext">Buy Dossier <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>
</button>
<button class="btn btnbg5 btnbg">
<div class="dropdown">
  <h7 class="shoptext">Order Testing <i class="fa-solid fa-caret-down"></i></h7>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>
</button>

<section>
<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="./ass/img/sp1.jpg" class="d-block w-100 spheight" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="./ass/img/sp2.jpg" class="d-block w-100 spheight" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="./ass/img/sp3.jpg" class="d-block w-100 spheight" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</section>




















{/* <center><br></br><br></br>
<h1 class="wow fadeIn" data-wow-delay="0.3s">Shop</h1></center>
<section class=" h-custom">
  <div class="container h-100 py-5">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="h5">Shopping Book</th>
                <th scope="col">Format</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div class="d-flex align-items-center">
                    <img src="https://i.imgur.com/2DsA49b.webp" class="img-fluid rounded-3"
                      style={{width:"120px"}} alt="Book"/>
                    <div class="flex-column ms-4">
                      <p class="mb-2">Thinking, Fast and Slow</p>
                      <p class="mb-0">Daniel Kahneman</p>
                    </div>
                  </div>
                </th>
                <td class="align-middle">
                  <p class="mb-0" style={{fontWeight: "500"}}>Digital</p>
                </td>
                <td class="align-middle">
                  <div class="d-flex flex-row">
                    <button class="btn btn-link px-2"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                      <i class="fas fa-minus"></i>
                    </button>

                    <input id="form1" min="0" name="quantity" value="2" type="number"
                      class="form-control form-control-sm" style={{width: "50px"}} />

                    <button class="btn btn-link px-2"
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </td>
                <td class="align-middle">
                  <p class="mb-0"style={{fontWeight: "500"}}>$9.99</p>
                </td>
              </tr>
              <tr>
                <th scope="row" class="border-bottom-0">
                  <div class="d-flex align-items-center">
                    <img src="https://i.imgur.com/Oj1iQUX.webp" class="img-fluid rounded-3"
                      style={{width: "120px"}} alt="Book"/>
                    <div class="flex-column ms-4">
                      <p class="mb-2">Homo Deus: A Brief History of Tomorrow</p>
                      <p class="mb-0">Yuval Noah Harari</p>
                    </div>
                  </div>
                </th>
                <td class="align-middle border-bottom-0">
                  <p class="mb-0" style={{fontWeight: "500"}}>Paperback</p>
                </td>
                <td class="align-middle border-bottom-0">
                  <div class="d-flex flex-row">
                    <button class="btn btn-link px-2"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                      <i class="fas fa-minus"></i>
                    </button>

                    <input id="form1" min="0" name="quantity" value="1" type="number"
                      class="form-control form-control-sm" style={{width: "50px" }}/>

                    <button class="btn btn-link px-2"
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </td>
                <td class="align-middle border-bottom-0">
                  <p class="mb-0" style={{fontWeight: "500"}}>$13.50</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

    

      </div>


      
    </div>












    
  </div>
</section> */}






<Fcontact></Fcontact>
<Footer></Footer>
</>
  )
}

export default Product