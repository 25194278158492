import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights7() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container mg1 py-5">
<center><h1 class="display-6 mb-3">Pilot Program: EMA-FDA Parallel Scientific Advice For Hybrid/Complex Generic Products - General Principles</h1></center><br></br>
<div class="container"><p class= "cr1">The European Medicines Agency (EMA) and the U.S. Food and Drug Administration (FDA) of the U.S. Department of Health and Human Services have established a pilot program to provide parallel scientific advice (PSA) to applicants of marketing authorization applications (MAAs) for hybrid products (EMA) and abbreviated new drug applications (ANDAs) for complex generic drug products, hereafter referred to as “complex products”. Successful collaboration may provide applicants with a deeper understanding of the basis of regulatory decisions, optimize product development, and avoid unnecessary replication of studies or unnecessary testing methodologies.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i6.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">
     There are three stages in this pilot PSA meeting process:<br></br> 
     1) Stage 1, applicants request a meeting with EMA and FDA<br></br>
     2) Stage 2, EMA and FDA assess the meeting package, the agencies conduct a preparatory bilateral meeting, and then conduct a trilateral meeting with the applicant<br></br>
     3) Stage 3, EMA and FDA communicate written responses to the applicant.  
       
        </p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> pilot program: ema-fda parallel scientific advice for hybrid/complex generic products - general principles, 15 sep 2021</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights7