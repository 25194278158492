import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Endtoend () {
  return (
  <>
  
  <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">End To End Compliance

           </h2>
          <p>
          Compliance to current Good Manufacturing Practice (cGMP) is essential for your long term success in the competitive market place. Ultimately a good quality management system ensures the patient compliance and manufacturing of safe medicines. We provide custom solutions towards your manufacturing compliance and stratgise GMP pipeline
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/e1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Solutions For End To End Compliance</h1>
                    <p class="mb-4">● Site Due diligence<br></br>
                    ● Liasing with agency on inspection<br></br>
                    ● Third Party/QP audits<br></br> 
                    ● SOP preparation<br></br>
                    ● GMP audit/CAPA services<br></br>
                    ● EU GMP Part I/II compliance and mock audit<br></br> 
                    ● 21 CFR Part 11/Annex 11 compliance<br></br>
                    ● End to End GMP support and consulting<br></br>
                    ● Onsite/online GMP Training<br></br>
                    
                   
                    </p>
  
  
  </div></div></div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  
  
  
  
  
  
  </>
  )
}

export default Endtoend 