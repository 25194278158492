import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights8() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container mg1 py-5">
<center><h1 class="display-6 mb-3">Guidance For Industry Post Marketing Adverse Event Reporting For Medical Products And Dietary Supplements During A Pandemic</h1></center><br></br>
<div class="container"><p class= "cr1">FDA anticipates that during the pandemic, industry and FDA workforces may be reduced because of high employee absenteeism while reporting of adverse events related to widespread use of medical products indicated for the treatment or prevention of the pathogen causing the pandemic may increase. The guidance from 2012 is revised to clarify that the guidance is applicable to any pandemic, not just an influenza pandemic. All adverse event data should be handled using each firm’s usual standard operating procedures, and regulatory and statutory requirements for adverse event reporting should be met to the maximum extent possible.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i7.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Firms should develop and prepare to implement their COOP (continuity of operations plan) during pandemic to adverse event reporting. However in case if the industry is unable to fulfill the requirement, the industry should have the declaration of pandemic including start and end date of pandemic and High absenteeism and/or other factors. During a pandemic, all firms are strongly encouraged to submit as many required reports as possible. This will minimize reporting burdens once adverse event reporting processes have been restored to the pre-pandemic state. Reports with regulatory timeframes of 30 days or less (e.g., 15-day reports, 30-day reports) should be submitted before periodic safety reports.</p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i>guidance for industry post marketing adverse event reporting for medical products and dietary supplements during a pandemic, may 2020, safety</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights8