import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights5() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container mg1 py-5">
<center><h1 class="display-6 mb-3">Management And Classification Of Reports Of Suspected Quality Defects In Medicinal Products And Risk-Based Decision-Making</h1></center><br></br>
<div class="container"><p class= "cr1">The procedure was revised in order to provide more comprehensive guidance following quality risk management principles. Harmonisation of procedures utilised in defect assessment and further categorisation and rapid alert transmission is essential to - quickly identify the level of impact of the defect on end users, -to reach a common harmonised decision among Competent Authorities, - to promote mutual reliance between Member States and partner authorities. These reports might include quality defects on APIs used as starting materials and in addition, also adverse drug reactions due to a defect in the quality of the product concerned. Member States are obliged to take all appropriate measures to ensure that a medicinal product is withdrawn from the market if it proves to be harmful. In case of an agreed batch recall, it is normally the responsibility of the company to recall a batch and to notify concerned authorities, professionals of the distribution chain and customers in accordance with EU Good Manufacturing Practice guides.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i4.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">A formal risk based classification for quality defect is done. The classification can be of High risk, Low risk and moderate risk. Based on the risk further action such as recall, rework on packing, cession of clinical trial, cession of additional supply of the affected batch. Samples of the batch concerned, related batches and related starting materials may be taken and analysed. This could also be applied to inspections coordinated by and conducted on behalf of the European Medicines Agency. Regular follow ups and completion of follow up activities should be monitored. All procedures, documents should be maintained update and verified at regular interval.</p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> https://www.ema.europa.eu/en/documents/regulatory-procedural-guideline/compilation-union-procedures-inspections-exchange-information_en.pdf</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights5