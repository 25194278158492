import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights2() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">BCS Based Biowaiver</h1></center><br></br>
<div class="container"><p class= "cr1">Two drug products containing the same drug substance or substances are considered bioequivalent if their bioavailability (rate and extent of drug absorption) after administration in the same molar dose lie within acceptable predefined limits. The biopharmaceutics classification system (BCS)-based biowaiver approach is intended to reduce the need for in vivo bioequivalence studies, i.e., it can provide a surrogate for in vivo bioequivalence. The BCS-based biowaiver is only applicable to immediate release, solid orally administered dosage forms or suspensions designed to deliver drug to the systemic circulation.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/b2.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">BCS-based biowaivers are applicable to drug products where the drug substance or substances exhibit high solubility and, either high permeability (BCS Class I) or low permeability (BCS Class III). A biowaiver is applicable when the drug substance(s) in test and reference products are identical and the drug product is the same dosage form and strength as the reference product. A biowaiver may also be applicable if test and reference products contain different salts provided that both belong to BCS Class I. Pro-drugs may be considered for a BCS-based biowaiver when absorbed as the pro-drug. In cases where the highest single therapeutic dose does not meet the high solubility criterion, but the highest strength of the reference product is soluble under the required conditions, BCS-based biowaivers can be supported based on demonstration of dose proportional pharmacokinetics (i.e., AUC and Cmax) over a dose range that includes the highest single therapeutic dose.</p></div>
  
     <br></br><br></br><br></br>
     <div class="container"><p class= "cr1">Usually the test product should have identical excipient as that of RLD, however there might be differences. Amount of excipient and mechanism of absorption may affect the absorption of drug substance hence it must be addressed during development. In class III molecule excipient selection places major role as the DS absorption takes place in specific site. Excipients that may affect absorption should be qualitatively the same and quantitatively similar, i.e., within ± 10% of the amount of excipient in the reference product. For BCS I molecule the DP should show very rapid or rapid dissolution and similarity has to be defined, whereas for BCS III the dissolution must be very rapid in order to get biowaiver.
The biowaiver reporting format should include tabular and graphical presentations showing individual and mean results and summary statistics. The report should include all excipients, their qualitative, and, where appropriate, quantitative differences between the test and reference products. A full description of the analytical methods employed, including validation and qualification of the analytical parameters, should be provided.<br></br>
The guidance is intended to avoid or reduce the need of animal study and human BE trials based on extensive in vitro characterization of the drug substance and drug product properties.
</p></div><br></br><br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> m9 biopharmaceutics classification system based biowaivers, guidance for industry, may 2021, ich.</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights2