import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights6() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Nitrosamine Impurity</h1></center><br></br>
<div class="container"><p class= "cr1">Good Manufacturing Practices (GMP, also referred to as 'cGMP' or 'current Good Manufacturing Practice') is the aspect of quality assurance that ensures that medicinal products are consistently produced and controlled to the quality standards appropriate to their intended use and as required by the product specification. Most GMP requirements are very general and open-ended, allowing each manufacturer to decide individually how to best implement the necessary controls. This provides much flexibility, but also requires that the manufacturer interpret the requirements in a manner which makes sense for each individual business</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i5.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Recently the guideline is updated with recommendation to manufacturers of API and Drug product to detect and prevent unacceptable levels of nitrosamine impurities in pharmaceutical products. Several drug products like angiotensin II receptor blockers (ranitidine, metformin etc.) showed the presence of nitrosamine impurities, which are probable human carcinogens. Thus risk assessment strategy for potential nitrosamine in pharmaceutical product has to be done. Confirmatory testing should be carried out using appropriately validated and sensitive methods in accordance with the prioritization deriving from the risk evaluation conducted. This guidance is applicable for all the products where API is synthetically produced. Acceptable nitrosamine intake limit is tabulated below,
     
     <br></br><br></br>
     The AI limit can be exceeded for medicinal products with a limited treatment period or intermittent treatment (e.g. once a week), higher daily exposures may be used as an adjusted interim limit. The approach described in the ICH M7 guideline.
     <br></br>
     If nitrosamine impurities are detected, levels should be reported in ng and ppm, along with the relevant calculations used to describe the potential exposure, based on the maximum daily dosage and duration of treatment.
     </p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> 1. control of nitrosamine impurities in human drugs. february 2021, pharmaceutical quality/ manufacturing standards/ current good manufacturing practice (cgmp), revision 1.<br></br>
  <br></br>2. https://www.ema.europa.eu/en/documents/other/temporary-interim-limits-nmba-dipna-eipna-impurities-sartan-blood-pressure-medicines_en.pdf
  </i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights6