import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Insights3() {
  return (
    <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>

    <div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Bispecific Antibody Development Programs</h1></center><br></br>
<div class="container"><p class= "cr1">This guidance provides recommendations to assist industry and other stakeholders involved in the development of bispecific antibodies. In addition to general considerations, the guidance provides recommendations for specific regulatory, quality, nonclinical, and clinical considerations for bispecific antibody development programs. Although this guidance is intended for bispecific antibodies, the principles discussed in this guidance may also inform the development of other types of bispecific protein products and multispecific products. This guidance focuses on a range of regulatory and scientific considerations for bispecific antibodies, but not on development of a particular bispecific antibody. This guidance discusses unique aspects for chemistry, manufacturing, and controls (CMC), as well as nonclinical and clinical development programs for bispecific antibodies.</p></div><br></br>
        <div class="container pb-5">
        <center> <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  
               
      <img class="img-fluid ihimg" src="./ass/img/i3.jpg"/>

     </div></center> <br></br><br></br>
     <div class="container"><p class= "cr1">Bispecific antibodies are those that function to bridge two target cells. This type of bispecific antibodies would require binding to both targets at the same time for efficacy. Bispecific antibodies with functions not involving bridging two target cells, such as a bispecific antibody that targets two soluble cytokines, binds different epitopes of the same tumor cell or viral antigen, or engages two different targets to mimic the function of an endogenous protein. In this case it doesn’t require to bind both target at once.</p></div>
  
    
   <br></br>
  <p ><b> <font color="Red">Reference  </font></b></p><br></br>
  <p> <i> bispecific antibody development programs guidance for industry, may 2021 pharmaceutical quality/cmc</i></p>
  </div></div>

















  <Fcontact></Fcontact>
  <Footer></Footer>
  </>
  )
}

export default Insights3