import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Careers() {
  return (
   <>
       <Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
   <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center py-5 mt-4">
            <h1 class="display-2 text-white mb-3 animated slideInDown">Join The Silmed Team 
            </h1>
           <p>
           Join Our team here at silmed scientific to reshape the world of Pharma. We are looking for people who are as excited as we are. Employees and employment are changing, and we are keeping up by embracing a more globally distributed way of working.
           </p>
            
        </div>
    </div> 





<div class='container'>

<center>
    <div class="accordion wow fadeInUp"   data-wow-delay="0.1s"id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Senior Medical Writer
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <h5 class="mb-3">Senior Medical Writer</h5>
       We at Silmed Scientific are empowered with a vision of public health safety with scientific evaluation and assessment of healthcare products and ensuring global reach of safe products. With scientific talent force located globally, our endeavour is to build a uniqueworking culture
      <br></br> <br></br>
    <a href='/Careers1' > <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Regulatory Affairs Manager (EU/US)
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <h5 class="mb-3"> Regulatory Affairs Manager (EU/US)</h5>
      The Regulatory Affairs Manager monitors the companys production activities and ensures compliance with applicable regulations and standard operating procedures. Their responsibilities revolve around devising regulation compliance strategies, creating training and orientation programs to inform the staff on the policies and standards, conducting internal inspections and information drives, and keeping abreast with the latest rules and regulations.
      <br></br> <br></br>
      <a href='/Careers2' > <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a>
       </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Regulatory Associate - Medical Writing
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <h5 class="mb-3">  Regulatory Associate - Medical Writing</h5>
      We are hiring an experienced medical writer with experience in CTD medical writing and experience in clinical development of medicines.
      <br></br> <br></br>
      <a href='/Careers3' > <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a>
         </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingfour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree">
      Manager Regulatory Affairs US and European Market
      </button>
    </h2>
    <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <h5 class="mb-3">Manager Regulatory Affairs US and European Market</h5>
      We are hiring dynamic regulatory affairs talents with capabilities in CMC and regulatory intelligence.
      <br></br> <br></br>
      <a href='/Careers4' > <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a>
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingfive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
      International Business Development Executive
      </button>
    </h2>
    <div id="collapsefive" class="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <h5 class="mb-3">International Business Development Executive</h5>
      We are hiring International Business Developers who are qualified to extend our global reach through expert discovery and exploration of new and untapped business opportunities and relationships.Business development executives are responsible for finding and retaining clients, encouraging extant clients to purchase added products or features, and remaining abreast of changes in consumption.
      <br></br> <br></br>
      <a href='/Careers5' > <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a>

       </div>
    </div>
  </div>
</div>
   
</center>  
</div>
   
   
 <Fcontact></Fcontact>  
   
  <Footer></Footer> 
   
   
   
   
   
   
   </>
  )
}

export default Careers