import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Solutioncc() {
  return (
  <>
  
  <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Solution for CROs/CMOs
           </h2>
          <p>
          Regulatory compliance is atmost of importance during the development and manufacturing of the product. Noncompliance issues and warnings from health authorities are common nowadays. It is important that data from development, manufacturing, and regulatory submissions are aligned and matched. We provide our expert support for compliance and ensure that the development meets the respective agency requirements.
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/s6.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">List Of Services</h1>
                    <p class="mb-4">● Development Due Diligence for US FDA/EU/EMA/MHRA<br></br>
                    ● End to End regulatory review support and advice during the development<br></br>
                    ● Review/preparation of formulation development report from raw data based on ICH/EMA<br></br> 
                    ● Review of analytical Development documentation<br></br>
                    ● Review of Method validation protocols/report<br></br>
                    ● Review of Master manufacturing records/batch Records/Packing Records<br></br> 
                    ● Review of Specificationst<br></br>
                    ● DMF review and advice<br></br>
                    ● Scientific strategies while development<br></br>
                    ● Reference product section/RLD review<br></br>
                    ● BE study waiver opinion/study design<br></br>
                    ● Offsite/online review and training to CROs and CMOs<br></br>
                    </p>
  
  
  </div></div></div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  
  
  
  
  
  
  </>
  )
}

export default Solutioncc