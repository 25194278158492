import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Services() {
  return (
   <>
          <Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
   <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center py-5 mt-4">
            <h1 class="display-2 text-white mb-3 animated slideInDown">Services
            </h1>
           <p>
           Innovative services with huge experience in the global pharmaceutical environment Silmed Scientific deliver potential services and also plans to include innovative technologies to deliver some services, Services as product and process outsourcing services
           </p>
            
        </div>
    </div> 
    <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/s1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Regulatory Affairs</h1>
                 
                    <p class="mb-1">We are global regulatory affairs leaders, capable of providing end to end regulatory support to global markets. Risk based thinking and strategical approach is critical for timely submission and quick market approval. Our experts are able to provide guidance and support from agency assessor perspective. We have experience i</p>
                    <br></br> <br></br><br></br> <br></br>
    <a href='/Regulatorya'><button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>  
                   
                </div>
            </div>
           
{/* second 2 */}
<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">API Services</h1>
                 
                 <p class="mb-1">Our End to End solutions includes expert services for Active Ingredient Manufacturers. We are capable of writing Active Substance Master Files (ASMF) or Drug Master Files (DMF) per requirements of regulatory agencies. Chemistry, Manufacturing, and control information in open and closed parts of DMF is the</p>
                 <br></br> <br></br><br></br> <br></br>
                 <a href='/Apis'> <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/s2.jpg" alt=""/>
                  
                   
                </div>
            </div>
{/* therd 3 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/s3.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Scientific Services</h1>
                   
                    <p class="mb-1">Our scientific support services start from Pre-Development planning and support throughout the assessment phase. We think strategically and use regulatory wisdom and analyse the regulatory, quality, safety, and efficacy situations from an assessor perspective. Our experts are having extensive experience working on requirements</p>
                    <br></br> <br></br><br></br> <br></br>
                    <a href='/Scientifics'>     <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                   
                </div>
            </div>

{/* forth 4 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">End To End Compliance</h1>
                 
                 <p class="mb-1">Compliance to current Good Manufacturing Practice (cGMP) is essential for your long term success in the competitive market place. Ultimately a good quality management system ensures the patient compliance and manufacturing of safe medicines. We provide custom solutions towards your manufacturing compliance and stratgise GM</p>
                 <br></br> <br></br><br></br> <br></br>
                 <a href='/Endtoend'>  <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/s4.jpg" alt=""/>
                  
                   
                </div>
            </div>
{/* fifth 5 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/s5.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Clinical & Non-Clinical</h1>
                 
                    <p class="mb-1">Our clinical /non-clinical experts have more than 20 years of experience working with different Clinical Research Organisations (CROs) and multinational pharmaceutical companies. These resources are equipped to provide you solutions to global drug development strategy.</p>
                    <br></br> <br></br><br></br> <br></br>
                    <a href='/Clinicalnc'>   <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                   
                </div>
            </div>

{/* sixth 6 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Solution for CROs/CMOs</h1>
                 
                 <p class="mb-1">Regulatory compliance is atmost of importance during the development and manufacturing of the product. Noncompliance issues and warnings from health authorities are common nowadays. It is important that data from development, manufacturing, and regulatory submissions are aligned and matched. We provide our</p>
                 <br></br> <br></br><br></br> <br></br>
                 <a href='/Solutioncc'>   <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/s6.jpg" alt=""/>
                  
                   
                </div>
            </div>

{/* seventh 7 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/s7.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Pharmacovigilance</h1>
                 
                    <p class="mb-1">Drug safety is an emerging subject and most of the global agencies and pharmaceutical companies are paying much attention to meet the requirements in this area and achieve compliance. We have experts who can take care of pharmacovigilance requirements and provide all kinds of support. We can write all</p>
                    <br></br> <br></br><br></br> <br></br>
                    <a href='/Pharmacovigilance'>      <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                   
                </div>
            </div>



{/* eighth 8 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Labelling Solutions</h1>
                 
                 <p class="mb-1">One of the greatest challenge in pharmaceutical industry is patient compliance. Effective and novel packaging solutions can address this challenge to great extent. Compliance in labelling information is challenging. Harmonisation of product information is always another hurdle in EU. We provide complete packaging/labelling</p>
                 <br></br> <br></br><br></br> <br></br>
                 <a href='/Labellings'>   <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button></a>
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/s8.jpg" alt=""/>
                  
                   
                </div>
            </div>





        </div>
    </div>
   
 <Fcontact></Fcontact>  
 <Footer></Footer>  
   
   
   
   
   
   
   </>
  )
}

export default Services