import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Careers2() {
  return (
<>
<Navbar></Navbar>
<br></br><br></br><br></br><br></br><br></br>
<div class="container-fluid mg1 py-5">
<center><h1 class="display-6 mb-3">Regulatory Affairs Manager (EU/US)</h1></center><br></br>
<center><p class= "cr1">The Regulatory Affairs Manager monitors the companys production activities and ensures compliance with applicable regulations and standard operating procedures. Their responsibilities revolve around devising regulation compliance strategies, creating training and orientation programs to inform the staff on the policies and standards, conducting internal inspections and information drives, and keeping abreast with the latest rules and regulations.</p></center><br></br>
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/ccc1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Responsibilities</h1>
                    <p class="mb-4">● RA professional with in-depth technical knowledge of CMC aspects, dossier compilation, review, evaluation and compilation for new marketing authorisations<br></br>
                    ● Hands on experience to review and approve submission documents in accordance with the regulatory guidelines, evaluate changes in CMC documentation with respect to its impact on regulatory submissions, strategize filing categories, compile submissions to Europe, FDA, and other international markets.<br></br>
                    ● Due Diligence and CMC strategy for complex injectable products is beneficial<br></br> 
                    ● Experience in CMC requirements for EU/US for solid dosage forms and/or complex injectables is a must; Strong CMC background in Ophthalmic products<br></br>
                    ● Coordinate with internal and external stakeholders and compile high quality regulatory submissions, within stipulated timelines and accordance to the guidelines, ensuring submission documents are accurate, compliant, and high-quality.<br></br>
                    ● Ability to assess regulatory documents in consideration and compliance with the regulatory requirements.<br></br> 
                    ● Independently evaluate change controls with respect to its impact on regulatory submissions, strategize filing category, compile submissions, and resolve complex issues as they raise for EU, international markets<br></br>
                    ● Strong interpersonal skills including verbal and written communication are essential in this collaborative work environment. Thorough experience with ANDA/EU DCP/ IMPD/IND writing is expected<br></br>
               
                    
                    
                   
                    </p>
  
  
  </div></div>
  
  <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Requirements</h1>
                <p class="mb-4">● MPharm/PhD/MSc Chemistry /Biotechnology<br></br>
                    ● 4-8 years prior experience.<br></br>
                   
                    </p>
               
              
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                {/* <img class="img-fluid w-100" src="./ass/img/cc2.jpg" alt=""/> */}
                  
                   
                </div>
            </div>
  
  
  
  
  
  
  </div></div>
 <center> <a href='/Contact'>     <button class="custom-btn btny-12"><span>Now!</span><span>Apply</span></button></a></center><br></br><br></br>
  <Fcontact></Fcontact>
 <Footer></Footer>




</>
  )
}

export default Careers2