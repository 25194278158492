import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Regulatorya() {
  return (
   <>
    <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Regulatory Affairs
           </h2>
          <p>
          We are global regulatory affairs leaders, capable of providing end to end regulatory support to global markets. Risk based thinking and strategical approach is critical for timely submission and quick market approval. Our experts are able to provide guidance and support from agency assessor perspective. We have experience in working for agencies like EMA, UK, MHRA, USFDA, MPA Sweden, MEB Netherlands, etc. We provide support for EU marketing authorization procedures like Decentralised Procedure (DCP), Mutual Recognition Procedure (MRP), Centralised Procedure (CP) etc.Silmed Scientific excels in assisting/preparing clients for submitting Abbreviated New Drug Applications (ANDA), New Drug Applications (NDA) to USFDA
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/r1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">EU Marketing Authorization Application (MAA) Services</h1>
                    <p class="mb-4">● EU registration strategy<br></br>
                    ● Regulatory support for DCP/ MRP / NP / CP applications<br></br>
                    ● Selection of Member States<br></br> 
                    ● Local representation and query handling<br></br>
                    ● Response to deficiencies<br></br>
                    ● Clinical expert consultation<br></br>
                    ● Scientific Advice<br></br>
                    ● Submission management services<br></br>
                    ● Brexit Advice<br></br>
                  
                    </p>
                  
                  
      {/* <button class="custom-btn btny-12"><span>More!</span><span>Know</span></button>
                    */}
                </div>
            </div>
           














           
{/* second 2 */}
<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-6 mb-3">Regulatory Affairs Global Registration Support services</h1>
                <p class="mb-4">● Dossier Due Diligence<br></br>
                    ● Dossier Writing CMC/Medical writing / Clinical document<br></br>
                    ● preparation (Module 1, Module 2.4, 2.5, 2.6, 2.7, Module 3 etc)<br></br> 
                    ● eCTD Publishing<br></br>
                    ● Due Diligence (For submission and Market acquisition)<br></br>
                    ● Labelling, Package Leaflet, Artwork<br></br>
                    
                  
                    </p>
               
                </div>
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img class="img-fluid w-100" src="./ass/img/r2.jpg" alt=""/>
                  
                   
                </div>
            </div>
{/* therd 3 */}

<div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/r3.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">Post Approval/Life Cycle Management</h1>
                    <p class="mb-4">● Variations– Advice/ Preparation/Strategy<br></br>
                    ● MAH transfers/Change of Ownership applications<br></br>
                    ● PIQ packages<br></br> 
                    ● Sunset clause advice<br></br>
                    ● Renewal Strategy and advice<br></br>
                    ● Product information updates<br></br>
                    
                  
                    </p>
                 
                </div>
            </div>

<div class="container">
<h1 class="display-6 mb-3">Post Approval/Life Cycle Management</h1>
<p class="mb-4">● US agent services<br></br>
                   
                  
                    </p>
                    <p class="mb-4"><i>We have Proven Track Record in managing challenges in EU DCP, MRP, NP and CP</i></p>
</div>







        </div>
    </div>
   
   <Fcontact></Fcontact>
   
   <Footer></Footer>
   
   
   
   
   
   
   </>
  )
}

export default Regulatorya