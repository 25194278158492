import React from 'react'
import Navbar from '../Navbar'
import Fcontact from './Fcontact'
import Footer from './Footer'
function Scientifics() {
  return (
  <>
  
  <Navbar></Navbar><br></br><br></br><br></br><br></br><br></br>
    
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
       <div class="container text-center py-5 mt-4">
           <h2 class="display-2 text-white mb-3 animated slideInDown">Scientific Services
           </h2>
          <p>
          Our scientific support services start from Pre-Development planning and support throughout the assessment phase. We think strategically and use regulatory wisdom and analyse the regulatory, quality, safety, and efficacy situations from an assessor perspective. Our experts are having extensive experience working on requirements for global agencies like USFDA, EMA, MHRA, MEB, TGA, etc. Our strategic thinking helps to effective planning and the fastest approval of the regulated products. We provide scientific support is not limited to complex dosage forms, biologics/biosimilars, fixed dose combinations. Early stage due diligence and discussion/debate with global agencies are required for advancing development and successful marketing authorization of the complex products.
          </p>
           
       </div>
   </div> 
   
   
   <div class="container-fluid mg1 py-5">
        <div class="container pb-5">
            <div class="row g-5 align-items-center mb-5">
                <div class="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid w-100" src="./ass/img/ss1.jpg" alt=""/>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-3">We Also Help Do The Following</h1>
                    <p class="mb-4">● Early stage due diligence / gap analysis<br></br>
                    ● Regulatory strategy / legal basis analysis<br></br>
                    ● Organise/ Attend EMA/ MHRA/ other EU Agency Scientific Advise Meetings.<br></br> 
                    ● Prepare for the meeting/ Develop content for negotiation at agency meetings.<br></br>
                    ● Strategy for discussing with agencies for further development<br></br>
                    ● India DCGI/CDSCO meetings<br></br> 
                    ● Orphan drug evaluation/advice on regulatory queries<br></br>
                    ● Similarity assessment/evaluation of characterization/comparability data assessment for biologics<br></br>
                    ● Review of Pharmaceutical development data<br></br>
                    ● Quality/clinical/non-clinical Development strategy<br></br>
                    ● Scientific writing of CMC/clinical/non-clinical data/ Modules<br></br>
                    ● CTA/IMPD/IND writing<br></br>
                   
                    </p>
  
  
  </div></div></div></div>
  
  <Fcontact></Fcontact>
  <Footer></Footer>
  
  
  
  
  
  
  
  </>
  )
}

export default Scientifics